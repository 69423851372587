import React, { useState, useEffect } from "react";
import "./App.css";
import ReactDOM from "react-dom";
import "./index.css";
import "./gridindex.css";
import MyAwesomeTable from "./Contacttrial";
import MyAwesomeTable2 from "./trial";
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import UITheme from "@bamboobox/b2-theme";
import AccountContactToggler from "./AccountContactToggler";
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";
import { useParams } from "react-router-dom";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import {
  FormGroup,
  Grid,
  FormControlLabel,
  Button,
  createMuiTheme,
  Switch,
  Typography,
} from "@material-ui/core/";
import { API_BASE_URL, AUTH_URL, LOGIN_URL ,FUNNEL_REPORT,ACCOUNT_SUMMARY_REPORT} from './constants';
import "@bamboobox/b2-theme/grid/style.css"
import ARROW_SVG from "@material-ui/icons/ArrowBackIos"
import {useLocation} from "react-router-dom";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core/";

import { useColumnVisibilityPersist, useColumnSortOrderPersist } from "@bamboobox/b2gridutils";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { v4 as uuidv4 } from "uuid";
//import SyncToCRM from "./SyncToCRM";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DownloadIcon from "@mui/icons-material/Download";
// import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Autocomplete, Collapse, Divider, Stack } from "@mui/material";
import { ACTIVATION_SERVICE_BASE_URL, CDP_URL } from "./constants";
//import "./Header.css";
import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  } from "@material-ui/core";
import { DragIndicator } from "@material-ui/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import moment from "moment";
import { toast } from "react-toastify";
import Alert from '@mui/material/Alert';
import "./toast.css"

//import GridTable from "@nadavshaar/react-grid-table";
// const UItheme = createMuiTheme({
//   palette: {
//     primary: { main: "#109CF1", contrastText:"#fbfbfb" },
//     secondary: { main: "#1F454F" },
//   },
//   typography: {
//     fontFamily: [
//       "Poppins",
//       "-apple-system",
//       "BlinkMacSystemFont",
//       '"Segoe UI"',
//       "Roboto",
//       '"Helvetica Neue"',
//       "sans-serif",
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(","),
//   },
//   components: {
//     MuiButton: {
//       defaultProps: {
//         size: "small",
//       },
//     },
//     MuiFilledInput: {
//       defaultProps: {
//         margin: "dense",
//       },
//     },
//     MuiFormControl: {
//       defaultProps: {
//         margin: "dense",
//       },
//     },
//     MuiFormHelperText: {
//       defaultProps: {
//         margin: "dense",
//       },
//     },
//     MuiIconButton: {
//       defaultProps: {
//         size: "small",
//       },
//       styleOverrides: {
//         sizeSmall: {
//           // Adjust spacing to reach minimal touch target hitbox
//           marginLeft: 4,
//           marginRight: 4,
//           padding: 12,
//         },
//       },
//     },
//     MuiInputBase: {
//       defaultProps: {
//         margin: "dense",
//       },
//     },
//     MuiInputLabel: {
//       defaultProps: {
//         margin: "dense",
//       },
//     },
//     MuiListItem: {
//       defaultProps: {
//         dense: true,
//       },
//     },
//     MuiOutlinedInput: {
//       defaultProps: {
//         margin: "dense",
//       },
//     },
//     MuiTable: {
//       defaultProps: {
//         size: "small",
//       },
//     },
//     MuiTextField: {
//       defaultProps: {
//         margin: "dense",
//       },
//     },
//     MuiToolbar: {
//       defaultProps: {
//         variant: "dense",
//       },
//     },
//   },
// });
// custom twoWay switch component
const TwoWaySwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.primary.main,
  },
  track: {
    backgroundColor: theme.palette.grey[500],
    '&$checked': {
      backgroundColor: theme.palette.grey[500],

    },

  },
}))(Switch);


function App(props) {

  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: false,
  });
  const search = useLocation().search;
  const filter = new URLSearchParams(search).get('filter');
  const icp =  new URLSearchParams(search).get('icp');
  const acc= new URLSearchParams(search).get('acc');
  let contact = false
  if (props.match?.params) {
    console.log("App props = ", props.match?.params)
    if (props.match.params.page === "contact") {
      contact = true

    }
  }

  const [toggled, setToggled] = useState(contact);
  let [dataServiceProvider, setProvider] = React.useState("");

  const handleProviderChange = (event) => {
    setProvider(event.target.value);
    dataServiceProvider = event.target.value;
  };
  const getValue = (e, value) => {
    setToggled(value);
  };
  
  

  const onBackClick = () => {
   //${FUNNEL_REPORT}
    if(acc==='asm')
    {
      let goBack=`${ACCOUNT_SUMMARY_REPORT}#/filter/${filter}`
      window.location.href = goBack
    }
    else{
    let goBack=`${FUNNEL_REPORT}filter/${filter}?icp=${icp}`
      window.location.href = goBack
    }
  }
  const [selectedType, setSelectedType] = React.useState("Account");
  const [tabVal,setTabVal] = React.useState(()=>{
    if(contact){
      return 1
    }

    return 0
  });


  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;

  const [columVisibilityTransformer, onColumnChangeSave] =
    useColumnVisibilityPersist("B2AMAccountEnrichment" + tenantId);
  let [columns, setColumns] = useState(
    columVisibilityTransformer([])
  );

  var columnsorder = columns;

  // function Alert(props) {
  //   return <MuiAlert elevation={6} variant="filled" {...props} />;
  // }
  // const closeSnackbar = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpenSnackbar(false);
  // };

  //   const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  //   const [campaignData, setCampaignData] = React.useState([]);
  //   const [uiMAPData, setUiMAPData] = React.useState([]);
  //   const [selectedMAP, setSelectedMAP] = React.useState({ tool: "" });
  //   const [selectedCampaign, setSelectedCampaign] = React.useState(null);
  //   const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  //   const [alertCount, setAlertCount] = React.useState(0);
  const [actionData, setActionData] = React.useState([]);
  const [downloadOpen, setDownloadOpen] = React.useState(false);
  const [type, setType] = React.useState("");
  //   const [checkVal, setcheckVal] = React.useState([]);
  const [selectCheckBox, setSelectCheckbox] = React.useState(0);
  //   const [downloadBody, setDownloadBody] = React.useState([]);
  //   const [addCampaignBody, setAddCampaignBody] = React.useState([]);
  //   const [addEnrichBody, setAddEnrichBody] = React.useState([]);
  const [checkedData, setCheckedData] = React.useState([]);
  // const [openSnackbar, setOpenSnackbar] = React.useState(false);
  // const [severity, setSeverity] = React.useState("");
  // const [message, setMessage] = React.useState("");
  //   const [syncCrmOpen, setSyncCrmOpen] = React.useState();
  const [objectsDataMAP, setObjectsDataMap] = React.useState([]);
  const [accountsDataMAP, setAccountsDataMap] = React.useState([]);
  const [contactsDataMAP, setContactsDataMap] = React.useState([]);
  const [object, setObject] = React.useState("account");
  const [showActions, setShowActions] = React.useState(true);

  const [isdisabled,setDisabled] = useState(true)

  console.log("value of object", object);

  useEffect(() => {
    if(tabVal === 0){
      setObject("account")
    }
    else if(tabVal === 1){
      setObject("contact")
    }
    console.log("setObject",object)
  })

  useEffect(() => {
    if(object === "account"){
      setObjectsDataMap(accountsDataMAP)
    }
    else{
      setObjectsDataMap(contactsDataMAP)
    }
    if([...objectsDataMAP].length>=1){
      setDisabled(false)
      console.log("length objectsDataMAP",[...objectsDataMAP].length,"isDisabled",isdisabled)
      console.log("length objectsDataMAP - Account",[...accountsDataMAP].length)
    }
  })

  
  console.log("objectsDataMAP", accountsDataMAP,contactsDataMAP);
  console.log("showActions", showActions);

  const handleClick = (event) => {
    setDownloadOpen(true);
    // setAnchorEl(event.currentTarget);
  };

//   const handleClose = async () => {
//     setAnchorEl(null);
//   };

  //   const open = Boolean(anchorEl);

  //   const handleClickOpen = (e) => {
  //     console.log("handleClickOpen", e.target.textContent);

  //     if (e.target.textContent == "Sync to CRM") {
  //       setSyncCrmOpen(true);
  //     }
  //     if (e.target.textContent == "Add to Campaign") {
  //       setIsAlertOpen(false);
  //       setSelectedMAP({ tool: "" });
  //       setSelectedCampaign(null);
  //       setAlertCount(0);
  //       setOpen(true);
  //     } else if (e.target.textContent == "Download") {
  //       setDownloadOpen(true);
  //     } else if (e.target.textContent == "Add to Enrichment Queue") {
  //       if (object == "account") {
  //         if (checkedData >= 1) {
  //           onAddEnrich();
  //           refresh[1](refresh[0] + 1);
  //           setOpen(false);
  //           setTimeout(() => {
  //             setSeverity("success");

  //             if (checkedData > 1) {
  //               setMessage(
  //                 `${checkedData} accounts are added to Enrichment Queue`
  //               );
  //             } else {
  //               setMessage(`${checkedData} account is added to Enrichment Queue`);
  //             }
  //             setOpenSnackbar(true);
  //           }, 4000);
  //         }
  //       } else {
  //         {
  //           if (checkedData >= 1) {
  //             onAddEnrichContact();
  //             refresh[1](refresh[0] + 1);
  //             setOpen(false);
  //             setTimeout(() => {
  //               setSeverity("success");
  //               if (checkedData > 1) {
  //                 setMessage(
  //                   `${checkedData} contacts are added to Enrichment Queue`
  //                 );
  //               } else {
  //                 setMessage(
  //                   `${checkedData} contact is added to Enrichment Queue`
  //                 );
  //               }
  //               setOpenSnackbar(true);
  //             }, 4000);
  //           }
  //         }
  //       }
  //     }
  //     setAnchorEl(null);
  //   };

  const handleeClose = () => {
    setOpen(false);
    setDownloadOpen(false);
  };

  const doDownload = async () => {
    const select = [...objectsDataMAP];
    // setOpenSnackbar(true);
    setDownloadOpen(false);
    // setSeverity("");
    {
      // if (checkedData > 1) {
      //   // setMessage(`${checkedData} ${object}s being downloaded`);
      //   toast.info(<Alert severity="info">{checkedData} {object}s being downloaded</Alert>,{
      //     closeOnClick: true,
      //     autoClose: true,
      //     closeButton: true,
      //     newestOnTop: true,
      //     autoClose: 3000
      //   });
      // } else {
      //   // setMessage(`${checkedData} ${object} being downloaded`);
      //   toast.info(<Alert severity="info">{checkedData} {object} being downloaded</Alert>,{
      //     closeOnClick: true,
      //     autoClose: true,
      //     closeButton: true,
      //     newestOnTop: true,
      //     autoClose: 3000

      //   });
      // }
    }
    const downloadData1 = [];
    const downloadData = {};
    const data = {};

    for (let j = 0; j < select.length; j++) {
      console.log("inside");
      const selectRow = select[j][1];
      const downloadItem = {};
      for (let i = 0; i < columnsorder.length; i++) {
        if (columnsorder[i].visible == true) {
          {
            downloadItem[columnsorder[i].label] =
              selectRow[columnsorder[i].field];
          }
        }
      }
      downloadData1.push(downloadItem);
    }

    // setDownloadBody(downloadData);
    const downloadColumn = {};

    for (let i = 0; i < columnsorder.length; i++) {
      if (columnsorder[i].visible == true) {
        downloadColumn[columnsorder[i].field] = columnsorder[i].label;
      }
    }
    let rightNow = new Date();
    let res = rightNow.toISOString().slice(0, 20).replace(/:/g, "");

    const fileName = `${object}Downolad_${res}.csv`;
    const bodyFileName = `${uuidv4(fileName)}.csv`;


    data["tenantId"] = tenantId;
    data["details"] = downloadData1;
    data["fileName"] = bodyFileName;

    downloadData["actionName"] = "download";
    downloadData["tenantId"] = tenantId;
    downloadData["type"] = object;
    downloadData["data"] = data;

    // downloadData["details"] = downloadData1;
    // downloadData["fileName"] = fileName;

    await getResult(downloadData, fileName, bodyFileName);
    // await getResult(downloadData1);
  };

  //   const onAddEnrichContact = () => {
  //     const contactData = [...objectsDataMAP].map((value) => {
  //       return value[1].email;
  //     });
  //     const contactEnrichBody = {
  //       actionName: "addToEnrichmentQueue",
  //       tenantId: tenantId,
  //       type: "contact",
  //       data: {
  //         tenantId: tenantId,
  //         emails: contactData,
  //       },
  //     };

  //     fetch(
  //       `${window._env_.ACTIVATION_SERVICE_BASE_URL}/activator/perform-action`,
  //       {
  //         method: "POST",
  //         credentials: "include",
  //         body: JSON.stringify(contactEnrichBody),
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     ).then((response) => {
  //       if (response.status === 200) {
  //       }
  //     });
  //   };
  // Add to enrich

  //   const onAddEnrich = () => {
  //     const data = [...objectsDataMAP].map((value) => {
  //       return value[1].accountWebsite;
  //     });

  //     const enrichBody = {
  //       actionName: "addToEnrichmentQueue",
  //       type: "account",
  //       data: {
  //         tenantId: tenantId,
  //         websites: data,
  //       },
  //     };

  //     fetch(
  //       `${window._env_.ACTIVATION_SERVICE_BASE_URL}/activator/perform-action`,
  //       {
  //         method: "POST",
  //         credentials: "include",
  //         body: JSON.stringify(enrichBody),
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     ).then((response) => {
  //       if (response.status === 200) {
  //       }
  //     });
  //   };

  const getResult = async (downloadData, fileName, bodyFileName) => {
    const response = await fetch(`${CDP_URL}/download-files/download`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(downloadData),
      credentials: "include",
    });
    
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        // window.location.assign(link);
        link.click();
        // setSeverity("success");
        if (checkedData > 1) {
          // setMessage(`${checkedData} ${object}s downloaded Successfully `);
          toast.success(<Alert severity="success">{checkedData} {object}s downloaded Successfully</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
        } else {
          // setMessage(`${checkedData} ${object} downloaded Successfully `);
          toast.success(<Alert severity="success">{checkedData} {object} downloaded Successfully</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
        }
      } else {
        // setOpenSnackbar(true);
        // setSeverity("error");
        // setMessage("Download Failed Try Again");
        toast.error(<Alert severity="error">Download Failed Try Again</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
      }
  
  };

  const isDisabledClose = () => {
    if (checkedData == 0) {
      return true;
    } else {
      return false;
    }
  };

  function handleTypeChange(event) {
    setType(event.target.value);
  }

  function sortColumns() {
    columnsorder.reverse();
    columnsorder.sort(function (a, b) {
      return a.visible - b.visible;
    });
    console.log("sorted", columnsorder.reverse());
  }

  React.useEffect(() => {
    if (showActions === true) {
      fetch(
        `${ACTIVATION_SERVICE_BASE_URL}/activator/get-registered-actions?tenantId=${tenantId}&type=${object}`,
        { credentials: "include" }
      )
        .then((response) => response.json())
        .then((response) => {
          try {
            setActionData(response.data);
          } catch (e) {
            console.log(e);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  React.useEffect(() => {
    if (showActions === true) {
      DownloadVisible();
    }
  }, [downloadOpen, showActions]);

  React.useEffect(() => {
    if (showActions === true) {
      setCheckedData([...objectsDataMAP].length);
    }
  }, [objectsDataMAP, showActions, isOpen]);

  const DownloadVisible = () => {
    let colvisi = 0;

    let colkey1 = columnsorder.findIndex((obj) => obj.id == "checkbox");
    if (colkey1 >= 0) {
      columnsorder.splice(colkey1, 1);
    }

    let colkey2 = columnsorder.findIndex((obj) => obj.id == "buttons");
    if (colkey2 >= 0) {
      columnsorder.splice(colkey2, 1);
    }

    columnsorder.map((value, key) => {
      if (value.visible) {
        colvisi++;
        console.log("hello", colvisi);
      }
    });

    setSelectCheckbox(colvisi);
    columnsorder.reverse();
    columnsorder.sort(function (a, b) {
      return a.visible - b.visible;
    });
    console.log("sorted", columnsorder.reverse());
  };





  return (
    <ThemeProvider theme={UITheme}>

      <div className="demo">
        <div className="pageWrapper">
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <div style={{display:"flex",flexDirection:"flex-start"}}>
              <Typography style={{ marginBottom: '8px', paddingTop:"8px", color:"#595959", fontSize:"20px", fontWeight:700, display: "inline-flex", alignItems:"center"}} > 
                {(filter===null || filter===undefined)?<React.Fragment></React.Fragment>:<ARROW_SVG style={{cursor:'pointer'}} onClick={onBackClick} fontSize="small"/>}
                Enrichment Queues
              </Typography>
            </div>
            <div style={{display: "flex",flexDirection: "flex-end",right:'34px'}}>
              <div className="source-provider" >
              <div className="autocompelete-section">
                {/* <Typography variant="body1"> Data Service Provider</Typography>
                &emsp; */}
                <FormControl variant="outlined" margin="dense">
                  <InputLabel id="demo-simple-select-label">
                    Data Service Pr...
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Data Service Pr..."
                    value={dataServiceProvider}
                    onChange={handleProviderChange}
                    size="medium"
                    style={{ width: "180px" }}
                  >
                    <MenuItem value={"None"}>Not Available</MenuItem>
                  </Select>
                </FormControl>
                <FormControl></FormControl>
              </div>
              </div>
          </div>
          </div>
          
          <div style={{display:"flex", paddingBottom:"16px"}}>
            <div style={{display:"flex"}}>
              <AccountContactToggler setSelectedType={setSelectedType} tabVal={tabVal} setTabVal={setTabVal} />
            </div>
            <div style={{padding:"8px"}}>
          {showActions == true ? (
          <div style={{ display: "flex", paddingTop: "2px" }}>
            <Button
            style={{ marginLeft: "10px",                 
            backgroundColor:checkedData === 0?"#C4C4C4":"#21DA8C", 
            borderRadius:"18px",
            color:"#FFFFFF",
            padding:"2px",
            width:"110px",
            fontWeight:700,
            fontSize:"12px"
            }}              
              onClick={handleClick}
              disabled={isdisabled}
            >
             DOWNLOAD
            </Button>

            {/* <div style={{ position: "relative", top: "150px" }}>
              <Popover
                sx={{
                  "& .MuiPopover-paper": {
                    width: "fit-content",
                    height: "fit-content",
                    marginTop: "-8px",
                    marginLeft: "-15px",
                  },
                }}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                {actionData.map((option) => (
                  <Typography
                    sx={{
                      padding: "6px 16px ",
                      cursor: "Pointer",
                      "&:hover": { backgroundColor: "#f2f2f2" },
                    }}
                    key={option.value}
                    value={option.value}
                    onClick={(e) => {
                      handleClickOpen(e);
                    }}
                  >
                    {option}
                  </Typography>
                ))}
              
              </Popover>
           
           
            
            
            
            
            
            
            
            
            
            
            </div> */}
            {/* <SyncToCRM
                objectType = {object}
                onContSyncToCrm={onContSyncToCrm}
                objectsDataMAP={objectsDataMAP}
                checkedData={checkedData}
                syncCrmOpen={syncCrmOpen}
                setSyncCrmOpen={setSyncCrmOpen}
              /> */}
            <div>
              <Dialog open={downloadOpen}>
                <DialogContent>
                  <div
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      width: "562px",
                      alignItems: "center"
                    }}
                  >
                    <Typography
                      variant="button large"
                      style={{ fontWeight: "bold" }}
                    >
                      Download {object}
                    </Typography>

                    <span style={{ paddingRight: "20px", paddingTop: "5px" }}>
                      <CloseOutlinedIcon
                        style={{ cursor: "pointer" }}
                        label="close"
                        onClick={handleeClose}
                      />
                    </span>
                  </div>
                  <div style={{ paddingBottom: "10px" }}>
                    <FormControl size="medium" variant="outlined">
                      <InputLabel id="demo-simple-select-label">
                        Export Type
                      </InputLabel>

                      <Select
                        style={{ width: "150px", height:"42px", borderRadius:"4px" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Export Type"
                        IconComponent={KeyboardArrowDownRoundedIcon}
                        defaultValue={1}
                        onChange={handleTypeChange}
                      >
                        <MenuItem value={1}>CSV</MenuItem>
                      </Select>
                    </FormControl>
                    {/* <Divider /> */}
                  </div>
                  <DragDropContext
                    onDragEnd={(param) => {
                      const srcI = param.source.index;
                      const desI = param.destination.index;
                      console.log("order", srcI, desI);

                      columnsorder.splice(
                        desI,
                        0,
                        columnsorder.splice(srcI, 1)[0]
                      );
                      console.log("afterOrder", columnsorder);
                      console.log(param);
                    }}
                  >
                    <Droppable droppableId="droppable-1">
                      {(propvided, _) => (
                        <div
                          ref={propvided.innerRef}
                          {...propvided.droppableProps}
                        >
                          <Paper
                            style={{ maxHeight: "400px", overflow: "auto" }}
                          >
                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                              }}
                            >
                              {columnsorder.map((value, key) => {
                                const labelId = key;
                                // console.log(value);

                                const labelValue = value.label;
                                return (
                                  <Draggable
                                    key={value.id}
                                    draggableId={"draggable-" + value.id}
                                    index={key}
                                  >
                                    {(propvided, snapshot) => (
                                      <ListItem
                                        key={value.id}
                                        ref={propvided.innerRef}
                                        {...propvided.draggableProps}
                                        {...propvided.dragHandleProps}
                                        secondaryAction={
                                          <IconButton
                                            edge="end"
                                            aria-label="comments"
                                          ></IconButton>
                                        }
                                        disablePadding
                                      >
                                        <ListItemIcon>
                                          <Checkbox
                                            color="primary"
                                            checked={value.visible}
                                            name={labelValue}
                                            onChange={(e) => {
                                              console.log("select checkbox", e);
                                              const labelName = e.target.name;
                                              let check =
                                                columnsorder.findIndex(
                                                  (obj) =>
                                                    obj.label == labelName
                                                );
                                              columnsorder[check].visible =
                                                e.target.checked;
                                              console.log(columnsorder);
                                              if (e.target.checked) {
                                                console.log(
                                                  "nameCheck",
                                                  e.target.name,
                                                  e.target.checked
                                                );
                                                setSelectCheckbox(
                                                  selectCheckBox + 1
                                                );
                                              } else {
                                                setSelectCheckbox(
                                                  selectCheckBox - 1
                                                );
                                                // onCheckClick(e)
                                              }
                                              sortColumns();
                                            }}
                                          />
                                        </ListItemIcon>

                                        <ListItemText
                                          id={labelId}
                                          primary={labelValue}
                                        />
                                        <DragIndicator />
                                      </ListItem>
                                    )}
                                  </Draggable>
                                );
                              })}
                            </List>
                          </Paper>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <div
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      padding: "24px 0px 0px 0px",
                    }}
                  >
                    {checkedData > 1 ? (
                      <typography component="body1">
                        {" "}
                        {checkedData} {object}s are Selected{" "}
                      </typography>
                    ) : (
                      <typography component="body1">
                        {" "}
                        {checkedData} {object} is Selected{" "}
                      </typography>
                    )}
                    <Stack direction="row" spacing={3}>
                      <Button
                        style={{borderRadius:"18px", backgroundColor:"#FFFFFF",color:"#A0A0A0", height:"32px"}}
                        variant="outlined"
                        onClick={handleeClose}
                      >
                            <div style={{ paddingTop: "1px", fontSize:"14px", fontWeight:600}}>CANCEL</div>
                      </Button>

                      <Button
                        style={{  borderRadius:"18px", backgroundColor:"#21DA8C",color:"#FFFFFF", height:"32px" }}
                        variant="contained"
                        disabled={
                            checkedData === 0
                            ? true
                            : false
                        }
                        onClick={() => {
                          doDownload();
                        }}
                      >
                              <div style={{paddingTop: "1px", fontSize:"14px", fontWeight:600 }}>CONFIRM</div>
                      </Button>
                    </Stack>
                  </div>
                </DialogContent>
              </Dialog>
              {/* // Download Dialog ending */}

              {/* <Dialog open={isOpen} onClose={handleClose}>
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    width: "562px",
                  }}
                >
                  <DialogTitle style={{ fontWeight: "bold" }}>
                    Assign To Campaign
                  </DialogTitle>
                  <span style={{ marginTop: "20px", paddingRight: "20px" }}>
                    <CloseOutlinedIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleeClose}
                    />
                  </span>
                </div>
                <DialogContent>
                  <Autocomplete
                    style={{ padding: 3 }}
                    size="small"
                    options={uiMAPData}
                    getOptionLabel={(option) => option.tool}
                    value={selectedMAP}
                    onChange={(event, value) => {
                      value === null
                        ? setSelectedMAP({ tool: "" })
                        : setSelectedMAP(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Marketing Platform"
                        type="text"
                        fullWidth
                        variant="outlined"
                      ></TextField>
                    )}
                  />
                  <Autocomplete
                    style={{ padding: 3 }}
                    size="small"
                    options={campaignData}
                    disabled={selectedMAP.tool === "" ? true : false}
                    value={selectedCampaign}
                    onChange={(event, value) => {
                      setSelectedCampaign(value);
                    }}
                    renderOption={(props, option) => (
                      <div>
                        <div {...props}>
                          <p2
                            style={{
                              position: "static",
                              color: "black",
                              paddingLeft: "8px",
                              fontSize: "14px",
                              lineHeight: "166%",
                              lineSpacing: "0.4px",
                              paddingLeft: "8px",
                            }}
                          >
                            {" "}
                            {option.programName}{" "}
                          </p2>
                          <p2
                            style={{
                              position: "static",
                              color: "rgba(0, 0, 0, 0.54)",
                              paddingLeft: "8px",
                              fontSize: "12px",
                              lineHeight: "166%",
                              lineSpacing: "0.4px",
                              paddingLeft: "8px",
                            }}
                          >{` | ${option.b2programType} | since ${moment(
                            option.programCreatedAt.substring(
                              0,
                              option.programCreatedAt.indexOf("+")
                            )
                          ).format("DD MMM YYYY")} `}</p2>
                        </div>
                      </div>
                    )}
                    getOptionLabel={(option) => option.programName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoFocus
                        // select

                        margin="dense"
                        id="name"
                        label="Campaign Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                      ></TextField>
                    )}
                  />
                </DialogContent>
              </Dialog> */}
              {/* {severity == "" ? (
                <Snackbar
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  open={openSnackbar}
                  autoHideDuration={12000}
                  onClose={closeSnackbar}
                >
                  <Alert
                    style={{
                      backgroundColor: "rgba(76, 175, 80, 1)",
                      lineHeight: "143%",
                      fontWeight: "500",
                      color: "#fff",
                    }}
                    onClose={closeSnackbar}
                    severity={severity}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress
                        style={{
                          color: "#fff",
                          top: "100%",
                          left: "5%",
                          bottom: "-93.41%",
                          right: "-5%",
                          marginRight: "10px",
                        }}
                        size="20px"
                      />
                      <div
                        style={{
                          fontWeight: "500",
                          color: "#fff",
                        }}
                      >
                        {message}
                      </div>
                    </div>
                  </Alert>
                </Snackbar>
              ) : (
                <Snackbar
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  open={openSnackbar}
                  autoHideDuration={12000}
                  onClose={closeSnackbar}
                >
                  <Alert onClose={closeSnackbar} severity={severity}>
                    {message}
                  </Alert>
                </Snackbar>
              )} */}
            </div>
          </div>
        ) : (
          <span></span>
        )}
          </div>
          
          </div>
          
          
          {/* <FormGroup className="acc_con">
            <Typography component="div">
              <div style={{ display: "inline-flex", alignItems: "center", marginLeft: "40" }}>
                <Typography variant="button">Account</Typography>
                <TwoWaySwitch
                  checked={toggled}
                  onChange={getValue}
                  color="default"
                  name="checkedC"
                />
                <Typography variant="button">Contact</Typography>
              </div>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <h2>Account</h2>
                </Grid>
                <Grid item>
                  <AntSwitch onChange={getValue} name="checkedC" />
                </Grid>
                <Grid item>
                  <h2>Contact</h2>
                </Grid>
              </Grid>
            </Typography>
          </FormGroup> */}
          {/* <Switch color="primary" size="small" onChange={getValue} /> */}
          {tabVal ? 
          <MyAwesomeTable  objectsDataMAP={objectsDataMAP} setContactsDataMap={setContactsDataMap} columns={columns} setColumns={setColumns} /> 
          : 
          <MyAwesomeTable2 objectsDataMAP={objectsDataMAP} setAccountsDataMap={setAccountsDataMap} columns={columns} setColumns={setColumns} />}
        </div>

      </div>

    </ThemeProvider>
  );
}

export default App;

ReactDOM.render(<AuthProvider loginURL={LOGIN_URL} authcheckURL={AUTH_URL}><App /></AuthProvider>, document.getElementById("root"));