import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import {  AuthProvider } from "@bamboobox/b2logincheck";
import { HashRouter as Router, Switch, Route, Link } from 'react-router-dom';
import {AUTH_URL,LOGIN_URL} from './constants';
import { version } from '../package.json';

console.log("verison of build", version);

// ReactDOM.render(
//   <React.StrictMode>
//     <App /> 
//     {/* <MyAwesomeTable /> */}
//   </React.StrictMode>,
//   document.getElementById('root')
// );
ReactDOM.render(<AuthProvider loginURL={LOGIN_URL} authcheckURL={AUTH_URL}>
    {/* <CacheBuster
        currentVersion={version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={true}> */}
    <Router>
        <Switch>
            <Route exact path='/' component={App} />
            <Route path='/:page' component={App} />
        </Switch>
        </Router>
    {/* </CacheBuster> */}
    </AuthProvider>, document.getElementById('root'))
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
