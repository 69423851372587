import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 

import Alert from '@mui/material/Alert';
import "./toast.css"

function ValidationUIComponent(props){
    
    let value=props.value
    let onChange=props.onChanged
    let data=props.data
    let column=props.column;
    let validator = props.validator;
    let message = props.message;
    let placeholder = props.placeholder
    let adornment=props.adornment;

    toast.configure({
      autoClose: 6000,
      draggable: true,
      hideProgressBar: false,
      position: toast.POSITION.BOTTOM_LEFT,
      icon: false,
    });

    var onFocusLost=async(event)=> {

        let inpValue= event.target.value;
        if (!inpValue || !inpValue.trim()) {
            console.log("empty value entered")
          return;
        }
        if (validator){
            console.log("validator is not empty")
            if(!validator(inpValue)) {
                console.log("wrong format of data entered")
                  toast.error(<Alert severity="error">{column.label+message}</Alert>,{
                    closeOnClick: true,
                    autoClose: true,
                    closeButton: true,
                    newestOnTop: true
                  });
                  return;
            }
        }
        console.log("onFocusLost",inpValue);
       
        
      }

      const handleChange = (event) => {
        let newValue = event.target.value;
        // console.log(newValue);
        onChange({ ...data, [column.field]: newValue });

      };


    return (
        
        <TextField id="standard-basic" value={value} onBlur={onFocusLost} onChange={handleChange} placeholder={placeholder} InputProps={{
          startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
        }}/>
        
      )
}

export default ValidationUIComponent;