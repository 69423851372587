import {validateUrl, validateEmail, validateFloat, validateInteger} from './validateInput';
    
export function saveAccountfromRow(row) {
         // var account = [..row];
         var account =
         {
             
                "accountId": row.accountId,
                "companyName": row.companyName,
                "companyGroupName": row.companyGroupName,
                "companyWebsite": row.companyWebsite,
                "companyParentWebsite": row.companyParentWebsite,
                "companyGroupWebsite": row.companyGroupWebsite,
                "companyPrimaryIndustryCategory": row.companyPrimaryIndustryCategory,
                "companySubIndustryCategory": row.companySubIndustryCategory,
                "companyBrandRevenueNumerical": row.companyBrandRevenueNumerical,
                "companyEmployeeCount": row.companyEmployeeCount,
                "companyFax": row.companyFax,
                "companyPhone": row.companyPhone,
                "companyStreet": row.companyStreet,
                "companyCity": row.companyCity,
                "companyState": row.companyState,
                "companyCountry": row.companyCountry,
                "companyContinent": row.companyContinent,
                "companyZipCode": row.companyZipCode,
                "companyDescription": row.companyDescription,
                "companyLinkedin": row.companyLinkedin,
                "companyFacebook": row.companyFacebook,
                "companyTwitter": row.companyTwitter,
                "companyParent": row.companyParent,
                "companyHierarchy": row.companyHierarchy,
                "companyLocationCount": row.companyLocationCount,
                "companyProducts": row.companyProducts,
                "companyType": row.companyType,
                "companyClassification": row.companyClassification,
                "companyTechAttributes": row.companyTechAttributes,
                "companyHashTag": row.companyHashTag,
                "companyAccountRegion": row.companyAccountRegion
         }
         return account;
     }
    
    
     
    
 export   function saveFundingfromRow(row) {
         //var funding = [..row];
         var funding = {
             "companyRecentFundingAmount": row.companyRecentFundingAmount,
             "companyRecentFundingDate": row.companyRecentFundingDate,
             "companyTotalFundingAmount": row.companyTotalFundingAmount,
             "companyFundingList": row.companyFundingList
         }
    
         return funding;
    
     }
    
export    function saveCRMfromRow(row)
    {   

        //var crm = [...row];
        var crm = {

                "crmAccountRegion": row.crmAccountRegion,
                "crmFmExecutive": row.crmFmExecutive,
                "crmBusinessFormat": row.crmBusinessFormat,
                "crmISExecutive": row.crmISExecutive,
                "crmFSExecutive": row.crmFSExecutive,
                "crmNumberOfStores": row.crmNumberOfStores,
                "crmAccountGeography": row.crmAccountGeography,
                "crmContactGeography":row.crmContactGeography,
                "crmPersonaCode": row.crmPersonaCode
    }
    
         return crm;
    
    }


    export function saveContactfromRow(row) {
    
    var contact = {
    "contactId": row.contactId,
    "contactSource": row.contactSource,
    "companyName": row.companyName,
    "companyGroupName": row.companyGroupName,
    "companyWebsite": row.companyWebsite,
    "contactRole": row.contactRole,
    "contactStreet": row.contactStreet,
    "contactCity": row.contactCity,
    "contactState": row.contactState,
    "contactCountry": row.contactCountry,
    "contactZipCode": row.contactZipCode,
    "contactFirstName": row.contactFirstName,
    "contactLastName": row.contactLastName,
    "contactTitle": row.contactTitle,
    "contactDepartment": row.contactDepartment,
    "contactSubDepartment": row.contactSubDepartment,
    "contactPositionLevel": row.contactPositionLevel,
    "contactLinkedin": row.contactLinkedin,
    "contactFacebook": row.contactFacebook,
    "contactTwitter": row.contactTwitter,
    "contactMobile": row.contactMobile,
    "contactPhone": row.contactPhone,
    "contactSecondaryNumber": row.contactSecondaryNumber,
    "contactEmail": row.contactEmail,
    "contactPersonalEmail": row.contactPersonalEmail,
    "contactSecondaryEmail": row.contactSecondaryEmail,
    "contactEducationHistory": row.contactEducationHistory,
    "contactEmploymentHistory": row.contactEmploymentHistory,
    "contactIsDefunct": row.contactIsDefunct,
    "contactMobilePhoneDoNotCall": row.contactMobilePhoneDoNotCall,
    "contactDirectPhoneDoNotCall": row.contactDirectPhoneDoNotCall,
    "contactEmailUnsubscribe": row.contactEmailUnsubscribe,
    
    "contactRegion":row.contactRegion
    }
    
    return contact;
    
    }
    
 
export    function addNewOnDelete(rowsClone){   

   
      var newRow= {
        "id": rowsClone.length+2, 
        "row_id": rowsClone.length+2,
        "msg": "",
        "accountSource": "Manual",
        "accountId": "",
        "companyName": "",
        "companyGroupName": "",
        "companyWebsite": "",
        "companyParentWebsite": "",
        "companyGroupWebsite": "",
        "companyPrimaryIndustryCategory": "",
        "companySubIndustryCategory": "",
        "companyBrandRevenueNumerical": 0,
        "companyEmployeeCount": "",
        "companyFax": "",
        "companyPhone": "",
        "companyStreet": "",
        "companyCity": "",
        "companyState": "",
        "companyCountry": "",
        "companyContinent": "",
        "companyZipCode": "",
        "companyDescription": "",
        "companyLinkedin": "",
        "companyFacebook": "",
        "companyTwitter": "",
        "companyParent": "",
        "companyHierarchy": "",
        "companyLocationCount": "",
        "companyProducts": "",
        "companyType": "",
        "companyClassification": "",
        "companyTechAttributes": "",
        "companyHashTag": "",
        "companyRecentFundingAmount": "",
        "companyRecentFundingDate": "",
        "companyTotalFundingAmount": "",
        "companyFundingList": "",
        "companyAccountRegion": "",
        "crmFmExecutive": "",
        "crmNumberofStores": "",
        "crmBusinessFormat": "",
        "crmISExecutive": "",
        "crmFSExecutive": "",
        "crmAccountGeography":""
      };
  
     return newRow;
    }

export function getAllRowsDatawithId(inp, counter, enriched) {
    var row = {
      id: counter,
      row_id: counter,
      ...inp.account,
      ...inp.funding,
      ...inp.crm,
      enriched: enriched,
    };
    
    return row;
  }

  export  function getAllRowsData(inp, counter, enriched) {
    var row = {
      id: counter,
      row_id: counter,
      ...inp.contact,
      ...inp.account,
      ...inp.contact,
      ...inp.funding,
      ...inp.crm,
      enriched: enriched,
    };

    Object.keys(row).forEach(k => row[k] = !row[k]? "" : row[k])
    
    return row;
  }

export  function getAllrows(inp_rows) {
    // console.log(inp_rows)
    console.log(inp_rows)
    var rows = inp_rows.data.rowSelected;
    var rows_out = [];

    for (let i = 0; i < rows.length; i++) {
      rows_out.push(getAllRowsData(rows[i], i + 1, 0));
    }
    console.log("check id");
    console.log(rows_out);
    return rows_out;
  }

  export function check_mandatory_validate_accountFields(row){
    let mandatoryFieldsError = "";
    let validationFieldError = "";
    let validationFieldErrorList = []
    if (!(row.companyWebsite) 
    || !(row.companyWebsite).trim())
    {
      mandatoryFieldsError+="Company Website, "
    }
  
    if ( !(row.companyName)
    || !(row.companyName).trim())
    {
      mandatoryFieldsError+="Company Name, "
    }
    if (mandatoryFieldsError){
      mandatoryFieldsError+="are mandatory and are missing"
    }
  
    if (!validateUrl(row.companyWebsite) ){
      validationFieldError += "Company Website, "
    }
  
    if (!validateUrl(row.companyParentWebsite) ){
      validationFieldError += "Company Parent Website, "
    }
  
    if (!validateUrl(row.companyGroupWebsite) ){
      validationFieldError += "Company Group Website, "
    }
  
    if (!validateUrl(row.companyLinkedin) ){
      validationFieldError += "Company Linkedin, "
    }
  
    if (!validateUrl(row.companyFacebook) ){
      validationFieldError += "Company Facebook, "
    }
  
    if (!validateUrl(row.companyTwitter) ){
      validationFieldError += "Company Twitter, "
    }
  
    if (validationFieldError) {
      validationFieldError+="Fields do not have website"
      validationFieldErrorList.push(validationFieldError)
      validationFieldError="";
    }
  
    if (!validateInteger(row.crmNumberOfStores) ){
      validationFieldError += "Number of stores, "
    }
  
    if (validationFieldError) {
      validationFieldError+="Fields should contain number only"
      validationFieldErrorList.push(validationFieldError)
      validationFieldError="";
    }
  
    if (!validateFloat(row.companyBrandRevenueNumerical) ){
      validationFieldError += "Company Brand Revenue, "
    }
  
    if (validationFieldError) {
      validationFieldError+="Fields should contain decimal only"
      validationFieldErrorList.push(validationFieldError)
      validationFieldError="";
    }
  
  
    return [mandatoryFieldsError, validationFieldErrorList];
    }
  
    
  
    export function check_mandatory_validate_contactFields(row){
      let mandatoryFieldsError = "";
      let validationFieldError = "";
      let validationFieldErrorList = []
      if (!(row.companyWebsite) || !(row.companyWebsite).trim())
      {
        mandatoryFieldsError+="Company Website, "
      }
    
      if ( !(row.companyName) || !(row.companyName).trim())
      {
        mandatoryFieldsError+="Company Name, "
      }
      if (!(row.contactLastName) || !(row.contactLastName).trim())
      {
        mandatoryFieldsError+="Last Name, "
      }
    
      if ( !(row.contactFirstName) || !(row.contactFirstName).trim())
      {
        mandatoryFieldsError+="First Name, "
      }
      if (!(row.contactEmail) || !(row.contactEmail).trim())
      {
        mandatoryFieldsError+="Contact Email, "
      }
      if (mandatoryFieldsError){
        mandatoryFieldsError+="are mandatory and are missing"
      }
    
      if (!validateUrl(row.companyWebsite) ){
        validationFieldError += "Company Website, "
      }
    
      if (!validateEmail(row.contactEmail) ){
        validationFieldError += "Official Email, "
      }
    
      if (!validateEmail(row.contactSecondaryEmail) ){
        validationFieldError += "Other Email, "
      }
    
      if (!validateUrl(row.contactLinkedin) ){
        validationFieldError += "Contact Linkedin Url, "
      }
    
      if (validationFieldError) {
        validationFieldError+="Fields do not have website"
        validationFieldErrorList.push(validationFieldError)
        validationFieldError="";
      }
    
      // if (!validateInteger(row.crmNumberOfStores) ){
      //   validationFieldError += "Number of stores, "
      // }
    
      // if (validationFieldError) {
      //   validationFieldError+="Fields should contain number only"
      //   validationFieldErrorList.push(validationFieldError)
      //   validationFieldError="";
      // }
    
      // if (!validateInteger(row.companyBrandRevenueNumerical) ){
      //   validationFieldError += "Company Brand Revenue, "
      // }
    
      // if (validationFieldError) {
      //   validationFieldError+="Fields should contain decimal only"
      //   validationFieldErrorList.push(validationFieldError)
      //   validationFieldError="";
      // }
    
    
      return [mandatoryFieldsError, validationFieldErrorList];
      }

      export function check_mandatory_contactFields(row){
        if ((!(row.companyWebsite) || !(row.companyWebsite).trim() || !(row.companyName) || !(row.companyName).trim()) || !(row.contactLastName) || !(row.contactLastName).trim() || !(row.contactFirstName) || !(row.contactFirstName).trim() ||
        ((!(row.contactEmail) || !(row.contactEmail).trim()) && (!(row.contactPersonalEmail) || !(row.contactPersonalEmail).trim()) && (!(row.contactSecondaryEmail) || !(row.contactSecondaryEmail).trim()) )) {
        // alert("No Mandatory fields passed");
        return false;
        } else { 
        return true;
        }
      }

      export function check_mandatory_accountFields(row){
        if ((!(row.companyWebsite) || !(row.companyWebsite).trim() || !(row.companyName) || !(row.companyName).trim()) ) {
        // alert("No Mandatory fields passed");
        return false;
        } else { 
        return true;
        }
      }

      export function convertTableDataToRequestJson(row) {
        var account =saveAccountfromRow(row)
    
        var contact = saveContactfromRow(row)
    
        var funding = saveFundingfromRow(row)
    
        var crm = saveCRMfromRow(row)
    
        var json = {"row_id" : row.id , "contactSource" : row.contactSource,"msg" : row.msg };
        json["contact"] = contact;
        json["account"] = account;
        json["funding"] = funding;
        json["crm"] = crm;
    
        return json;
    
      }


      export function convertTableDataToRequestJsonAccount(row) {
        var account =saveAccountfromRow(row)
    
        var funding = saveFundingfromRow(row)
    
        var crm = saveCRMfromRow(row)
    
        var json = {"row_id" : row.id , "accountSource" : row.accountSource, "msg" : row.msg };
        
        json["account"] = account;
        json["funding"] = funding;
        json["crm"] = crm;
    
        return json;
    
      }