import React, { useRef, useState, useEffect } from "react";
import ReactDOM from "react-dom";
//import GridTable from "@nadavshaar/react-grid-table";
import GridTable from "./grid_src";
import GetColumns from "./getContactColumns";
import REMOVE_ICON from "@material-ui/icons/HighlightOff";
import EnrichIcon from "./icons/enrichIcon";
import { toast } from "react-toastify";
import Paper from '@mui/material/Paper';
import {
  getAllRowsData,
  getAllrows,
  convertTableDataToRequestJson,
  check_mandatory_contactFields,
} from "./functionRepositoryDataSaveGet";
import { useColumnVisibilityPersist } from "@bamboobox/b2gridutils";
import { ViewColumn } from "@material-ui/icons";
import loadMetaData, { createHierarchy } from "./LoadMetaData.js";

import {
  MenuItem,
  Select,
  Button,
  FormControl,
  InputLabel,
  createMuiTheme,
} from "@material-ui/core/";
//import Autocomplete from '@material-ui/lab/Autocomplete';

import "./index.css";
import "./gridindex.css";

import axios from "axios";
//import { ControllersDrawer } from '../components';
import { ThemeProvider } from "@material-ui/core/styles";

import { withStyles } from "@material-ui/core/styles";

import {
  FormGroup,
  Grid,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core/";
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";
import { API_BASE_URL, AUTH_URL, LOGIN_URL } from "./constants.js";
import OutlinedSelectInput from "./components/OutlinedSelectInput";
import UItheme from "@bamboobox/b2-theme";
import "@bamboobox/b2-theme/grid/style.css";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import Alert from '@mui/material/Alert';
import "./toast.css"
//const localhost = window._env_.API_DOMAIN;
var obj1 = {
  id: 1, // gridData.current.data.rowSelected.length + 1,
  first_name: "",
  row_id: 1,
  contactid: "",
  contactFirstName: "",
  contactLastName: "",
  contactTitle: "",
  contactDepartment: "",
  contactSubDepartment: "",
  contactPositionLevel: "",
  contactLinkedin: "",
  contactFacebook: "",
  contactTwitter: "",
  contactMobile: "",
  contactPhone: "",
  contactSecondaryNumber: "",
  contactEmail: "",
  contactPersonalEmail: "",
  contactSecondaryEmail: "",
  contactEducationHistory: "",
  contactEmploymentHistory: "",
  contactIsDefunct: "",
  contactMobilePhoneDoNotCall: "",
  contactDirectPhoneDoNotCall: "",
  contactEmailUnsubscribe: "",

  accountId: "",
  companyName: "",
  companyGroupName: "",
  companyFranchiseeParent: "",
  companyWebsite: "",
  companyParentWebsite: "",
  companyGroupWebsite: "",
  companyPrimaryIndustryCategory: "",
  companySubIndustryCategory: "",
  companyBrandRevenue: "",
  companyParentRevenue: "",
  companyGroupRevenue: "",
  companyEmployeeCount: "",
  companyParentEmployees: "",
  companyGroupEmployees: "",
  companyFax: "",
  companyPhone: "",
  companyStreet: "",
  companyCity: "",
  companyState: "",
  companyCountry: "",
  companyContinent: "",
  companyZipCode: "",
  companyDescription: "",
  companyLinkedin: "",
  companyFacebook: "",
  companyTwitter: "",
  companyParent: "",
  companyGtmSegment: "",
  companyGtmRegion: "",
  companyLocationCount: "",
  companyProducts: "",
  companyEmployeeGrowth: "",
  companyType: "",
  companyClassification: "",
  companyTechAttributes: "",
  companyHashTag: "",
  companyRecentFundingAmount: "",
  companyRecentFundingDate: "",
  companyTotalFundingAmount: "",
  companyFundingList: "",
  crmNumberofStores: "",
  crmBusinessFormat: "",
  crmISExecutive: "",
  crmFSExecutive: "",
  crmDataSource: "",
  crmFmExecutive: null,
  contactSource: "Manual",
};

const MyAwesomeTable = (props) => {
  const [allMetadata, setAllMetaData] = useState([]);
  const [tenantField, setTenantField] = useState({});
  const [metadata, setMetaData] = useState([]);
  const [stateMetaData, setStateMetataData] = useState([]);
  const [tableManager, setTableManager] = useState(null);
  const [rowsData, setRowsData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [editRowId, setEditRowId] = useState(null);
  let [searchText, setSearchText] = useState("");
  let [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [selectedAccountData, setSelectedAccountData] = useState(new Map());
  let [sort, setSort] = useState({ colId: "enriched", isAsc: false });
  let [page, setPage] = useState(1);
  let [pageSize, setPageSize] = useState(15);
  let [pageSizes, setPageSizes] = useState([15, 50, 75, 100]);
  let [enableColumnsReorder, setEnableColumnsReorder] = useState(true);
  let [highlightSearch, setHighlightSearch] = useState(false);
  let [showSearch, setShowSearch] = useState(false);
  let [showRowsInformation, setShowRowsInformation] = useState(true);
  let [showColumnVisibilityManager, setShowColumnVisibilityManager] =
    useState(true);
  let [isHeaderSticky, setIsHeaderSticky] = useState(true);
  let [isVirtualScroll, setIsVirtualScroll] = useState(true);
  let [isPaginated, setIsPaginated] = useState(true);
  let [minSearchChars, setMinSearchChars] = useState(2);
  let [minColumnResizeWidth, setMinColumnWidth] = useState(70);
  const [columVisibilityTransformer, onColumnChangeSave] =
    useColumnVisibilityPersist("B2AMContactEnrichment");
  const defaultContactColumns=columVisibilityTransformer(
    GetColumns({
      setRowsData,
      metadata,
      stateMetaData,
      setSelectedRowsIds,
      allMetadata,
    })
  )
  // let [columns, setColumns] = useState(
  //   []
  // );
  let [isSettingsOpen, setIsSettingsOpen] = useState(false);
  let [selectAllMode, setSelectAllMode] = useState("page");
  const [isRowsLoaded, setIsRowsLoaded] = useState(false);
  const [isColsLoaded, setIsColsLoaded] = useState(false);
  const [state, setState] = React.useState({
    checkedC: true,
  });
  let gridData = useRef();
  let [dataServiceProvider, setProvider] = React.useState("");

  const localhost = API_BASE_URL;
  const userData = useAuthState();
  let tenantId = userData.user.tenantId;
  let userId = userData.user.userUuid;

  var provider = {
    DataServiceProvider: dataServiceProvider,
    tenantId: tenantId, // "TTT-ID-00000",
    userID: userId, // "USR-ID-0000",

    rowSelected: [],
  };
  var adapt = {
    tenantId: tenantId, // "TTT-ID-00000",
    userId: userId, // "USR-ID-0000",
    rowSelected: [],
  };

  useEffect(() => {
    setLoading(true);
    // loadMetaData(setMetaData, setStateMetataData, null, null, tenantId);
    createHierarchy(tenantId, setAllMetaData, setTenantField);
    // setTimeout(() => {
    //setRowsData(MOCK_DATA);    //Get API
    //console.log((MOCK_DATA))
    axios
      .get(
        localhost + "/b2pm/contact?tenantid=" + tenantId + "&userid=" + userId,
        { withCredentials: true }
      )
      .then((response) => {
        // gridData.current = tabledata;
        let temp = getAllrows(response);
        setRowsData(temp);
        setIsRowsLoaded(true);
        // console.log(typeof tabledata);

        //  setCounter( gridData.current.data.rowSelected.length+1)
      })
      .catch(function (error) {
        toast.error(<Alert severity="error">Unable to fetch list due to Server error</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
        console.log(error);
        setLoading(false);
      });

    //setLoading(false);
    // }, 1500);
  }, []);

  useEffect(()=>{
    if(Object.keys(tenantField).length!=0){
      const newColumns = [];
      for(const cols of defaultContactColumns) {
        if(cols.field === undefined || cols.field == "enriched") {
          newColumns.push(cols);
        }
        else if(cols.field in tenantField) {
          cols.label = tenantField[cols.field].label;
          newColumns.push(cols);
        }
      }
      console.log("cols.field",newColumns)
      props.setColumns([...newColumns]);
      setIsColsLoaded(true);
    }
  },[tenantField])

  useEffect(()=>{
    if(isRowsLoaded&&isColsLoaded){
      setLoading(false);
    }
  },[isRowsLoaded,isColsLoaded])

  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: false,
  });

  // useEffect(() => {
  //   loadMetaData(setMetaData, setStateMetataData, tenantId);
  //   setLoading(true);
  //   setTimeout(() => {
  //     setRowsData(rowsData);

  //     setLoading(false);
  //   }, 1500);
  // }, []);

  const arrange = (p) => {
    p.sort();
    for (var i = 0; i < p.length; i++) {
      rowsData.splice(p[i] - 1 - i, 1);
    }
    var data = rowsData;
    setRowsData([...data]);
  };

  var removeRows = {
    tenantId: tenantId, //"TTT-ID-00000",
    userId: userId, // "USR-ID-0000",
    rows: [],
  };

  const removeRowsFromFrontEnd = (rowSelected) => {
    rowSelected.sort();
    let index;
    let i = 0;
    console.log("removeRowsFromFrontEnd ", rowSelected);

    // while (i < rowsData.length) {

    //   index = rowSelected.indexOf(rowsData[i].id);
    //   console.log("removeRowsFromFrontEnd index", index)
    //   if (index !== -1) {
    //     console.log("removeRowsFromFrontEnd i", i)
    //     console.log("removeRowsFromFrontEnd removed", true)
    //     rowsData.splice(i, 1);
    //   } else {

    //     console.log("removeRowsFromFrontEnd removed", false)
    //     i++;
    //   }
    // }

    for (let i = 0; i < rowSelected.length; i++) {
      let index = rowsData.findIndex((p) => p.id === rowSelected[i]);
      console.log("index", index);
      if (index !== -1) {
        rowsData.splice(index, 1);
      }
    }

    var data = rowsData;
    console.log("removeRowsFromFrontEnd change", data);
    setRowsData([...data]);
  };

  const removeRowsFromApi = () => {
    let errorInOperation = 0;
    let successfulOperation = 0;

    let index;

    let rowsToBeDeleted = [];
    let rowsToBekept = [];
    let rowsContactId = [];

    let rowSelected = selectedRowsIds;
    rowSelected.sort();
    console.log("rowSelected", rowSelected);
    for (let i = 0; i < rowsData.length; i++) {
      index = rowSelected.indexOf(rowsData[i].id);
      if (index !== -1) {
        console.log("i = ", i);
        rowsContactId.push({ id: rowsData[i].contactId });
      }
    }

    removeRows.rows = rowsContactId;
    console.log("removeRowsFromApi", removeRows);
    axios
      .post(localhost + "/b2pm/contact/remove", removeRows, {
        withCredentials: true,
      })
      .then((response) => {
        for (let i = 0; i < response.data.rows.length; i++) {
          if (response.data.rows[i].msg === "SUCCESS") {
            rowsToBeDeleted.push(rowSelected[i]);
            successfulOperation++;
          } else {
            rowsToBekept.push(rowSelected[i]);
            errorInOperation++;
          }
        }

        console.log("rowsToBeDeleted", rowsToBeDeleted);
        removeRowsFromFrontEnd(rowsToBeDeleted);
        console.log("rowsToBekept", rowsToBekept);
        setSelectedRowsIds(rowsToBekept);

        // setSelectedRowsIds([]);
        if (successfulOperation !== 0) {
          toast.success(<Alert severity="success">{successfulOperation} Record/s have been successfully removed from Enrichment Queue</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
        }
        if (errorInOperation !== 0) {
          toast.error(<Alert severity="error">{errorInOperation} Failed due to Server error</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
        }
      })
      .catch(function (error) {
        toast.error(<Alert severity="error">Failed due to Server error</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
      });
  };

  // const removeAll = (e) => {
  //   for (let i = 0; i < selectedRowsIds.length; i++) {
  //     console.log(selectedRowsIds[i]);
  //     console.log(rowsData[selectedRowsIds[i] - 1].contactId);
  //     var cId = { id: rowsData[selectedRowsIds[i] - 1].contactid };
  //     console.log(cId);
  //     removeRows.rows.push(cId);
  //     console.log(removeRows);
  //   }

  //   axios
  //     .post(localhost + "/b2pm/contact/remove", removeRows)
  //     .then((tabledata) => {
  //       arrange(selectedRowsIds);
  //       console.log(selectedRowsIds);
  //       selectedRowsIds.splice(0, selectedRowsIds.length);
  //       setSelectedRowsIds(selectedRowsIds);
  //       console.log(selectedRowsIds);
  //       setSelectedRowsIds([])
  //     });

  //   console.log(removeRows.rows.length);
  // };
  // //A/C VIEW
  // const selectedRowIds = () => {
  //   console.log(" Contactdata a/c view -> ", selectedRowsIds);
  //   setSelectedRowsIds([]);
  //   props.resetCallselectedRowIds();
  // };

  const enrichContacts = (event) => {
    console.log("clicked enrichment");

    toast.error(<Alert severity="error">Enrichment not available for Contacts as of now</Alert>,{
      closeOnClick: true,
      autoClose: true,
      closeButton: true,
      newestOnTop: true
    });

    setSelectedRowsIds([]);
    return;
    if (dataServiceProvider === "Mock" || dataServiceProvider === "AdaptIO") {
      let errorInOperation = 0;
      let noDataAvailable = 0;
      let successfulOperation = 0;
      let rowsContacts = [];

      let rowSelected = selectedRowsIds;
      rowSelected.sort();

      let indexToBeUpdated = [];
      let index;

      for (let i = 0; i < rowsData.length; i++) {
        index = rowSelected.indexOf(rowsData[i].id);
        if (index !== -1) {
          indexToBeUpdated.push(i);
          rowsContacts.push(convertTableDataToRequestJson(rowsData[i]));
        }
      }
      console.log(rowsContacts, rowsContacts);
      adapt.rowSelected = rowsContacts;
      console.log("dsp = ", dataServiceProvider);
      console.log(
        "api link = ",
        localhost + "/b2pm/enrichment/contact/" + dataServiceProvider
      );
      axios
        .post(
          localhost + "/b2pm/enrichment/contact/" + dataServiceProvider,
          adapt,
          { withCredentials: true }
        )
        .then((response) => {
          let rowsClone = rowsData;

          for (let i = 0; i < response.data.rowSelected.length; i++) {
            if (response.data.rowSelected[i].msg === "SUCCESS") {
              rowsClone[indexToBeUpdated[i]] = getAllRowsData(
                response.data.rowSelected[i],
                rowsClone[indexToBeUpdated[i]].id,
                1
              );
              successfulOperation++;
            } else if (
              response.data.rowSelected[i].msg ===
              "FAILED - no data/response recieved"
            ) {
              noDataAvailable++;
            } else {
              errorInOperation++;
            }
          }
          console.log("setting rowsData", rowsClone);
          setRowsData([...rowsClone]);
          // setSelectedRowsIds([]);
          if (successfulOperation !== 0) {
              toast.success(<Alert severity="success">{successfulOperation} Records have been Successfully Enriched</Alert>,{
                closeOnClick: true,
                autoClose: true,
                closeButton: true,
                newestOnTop: true
              });
          }

          if (noDataAvailable !== 0) {
            toast.error(<Alert severity="error">{noDataAvailable} Records Failed to Enrich because no data available from provider</Alert>,{
                  closeOnClick: true,
                  autoClose: true,
                  closeButton: true,
                  newestOnTop: true
                });
          }

          if (errorInOperation !== 0) {
                toast.error(<Alert severity="error">{errorInOperation} Records Failed to Enrich due to Server error</Alert>,{
                  closeOnClick: true,
                  autoClose: true,
                  closeButton: true,
                  newestOnTop: true
                });
          }
          setSelectedRowsIds([]);
        })
        .catch(function (error) {
            toast.error(<Alert severity="error">Failed due to Server error</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
        });
    } else {
        toast.error(<Alert severity="error">Please select Data Service Provider for Enrichment</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
    }
  };

  const saveEnrichData = (event) => {
    let errorInOperation = 0;
    let successfulOperation = 0;

    let mandatory_fields = false;

    let index;

    let rowsToBeDeleted = [];
    let rowsToBekept = [];
    let rowsContacts = [];

    let selectedRows = selectedRowsIds;
    selectedRows.sort();

    for (let i = 0; i < rowsData.length; i++) {
      index = selectedRows.indexOf(rowsData[i].id);
      if (index !== -1) {
        if (!check_mandatory_contactFields(rowsData[i])) {
          console.log("Mandatory fields not present");
          mandatory_fields = true;
            toast.error(<Alert severity="error">Please fill Mandatory fields for all selected rows</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
          break;
        }
        console.log("Row enriched ? ", rowsData[i].enriched);
        // if (!rowsData[i].enriched) {
        //   console.log("Row not enriched")
        //   mandatory_fields = true;
        //   toast.error("You have selected rows that are not enriched", {
        //     className: "custom_toast_success",
        //     closeOnClick: true,
        //     autoClose: true,
        //     closeButton: true,
        //     newestOnTop: true
        //   });
        //   break;
        // }
        rowsContacts.push(convertTableDataToRequestJson(rowsData[i]));
      }
    }

    if (!mandatory_fields) {
      adapt.rowSelected = rowsContacts;
      console.log(adapt);
      axios
        .post(localhost + "/b2pm/contact/enrich/save", adapt, {
          withCredentials: true,
        })
        .then((response) => {
          for (let i = 0; i < response.data.rowSelected.length; i++) {
            if (response.data.rowSelected[i].msg === "SUCCESS") {
              rowsToBeDeleted.push(selectedRows[i]);
              successfulOperation++;
            } else {
              console.log("rowSelected", selectedRows[i]);
              console.log("successfulOperation", successfulOperation);
              rowsToBekept.push(selectedRows[i]);
              errorInOperation++;
            }
          }

          removeRowsFromFrontEnd(rowsToBeDeleted);
          console.log("rowsToBekept", rowsToBekept);
          setSelectedRowsIds(rowsToBekept);

          // setSelectedRowsIds([]);
          if (successfulOperation !== 0) {
                toast.success(<Alert severity="success">{successfulOperation} Records have been Successfully synced to CRM</Alert>,{
                  closeOnClick: true,
                  autoClose: true,
                  closeButton: true,
                  newestOnTop: true
                });
          }
          if (errorInOperation !== 0) {
            toast.error(<Alert severity="error">{errorInOperation} Records Failed due to Server error</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
          }
        })
        .catch(function (error) {
          toast.error(<Alert severity="error">Failed due to Server error</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
        });
    }
  };

  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 70,
      height: 30,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          width: 70,
        },
      },
    },
    thumb: {
      width: 25,
      height: 25,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleProviderChange = (event) => {
    setProvider(event.target.value);
    dataServiceProvider = event.target.value;
    console.log(dataServiceProvider);
  };

  // const UItheme = createMuiTheme({
  //   palette: {
  //     primary: { main: "#109CF1", contrastText: "#fbfbfb" },
  //     secondary: { main: "#1F454F" },
  //   },
  //   typography: {
  //     fontFamily: [
  //       "Poppins",
  //       "-apple-system",
  //       "BlinkMacSystemFont",
  //       '"Segoe UI"',
  //       "Roboto",
  //       '"Helvetica Neue"',
  //       "sans-serif",
  //       '"Apple Color Emoji"',
  //       '"Segoe UI Emoji"',
  //       '"Segoe UI Symbol"',
  //     ].join(","),
  //   },
  //   components: {
  //     MuiButton: {
  //       defaultProps: {
  //         size: "small",
  //       },
  //     },
  //     MuiFilledInput: {
  //       defaultProps: {
  //         margin: "dense",
  //       },
  //     },
  //     MuiFormControl: {
  //       defaultProps: {
  //         margin: "dense",
  //       },
  //     },
  //     MuiFormHelperText: {
  //       defaultProps: {
  //         margin: "dense",
  //       },
  //     },
  //     MuiIconButton: {
  //       defaultProps: {
  //         size: "small",
  //       },
  //       styleOverrides: {
  //         sizeSmall: {
  //           // Adjust spacing to reach minimal touch target hitbox
  //           marginLeft: 4,
  //           marginRight: 4,
  //           padding: 12,
  //         },
  //       },
  //     },
  //     MuiInputBase: {
  //       defaultProps: {
  //         margin: "dense",
  //       },
  //     },
  //     MuiInputLabel: {
  //       defaultProps: {
  //         margin: "dense",
  //       },
  //     },
  //     MuiListItem: {
  //       defaultProps: {
  //         dense: true,
  //       },
  //     },
  //     MuiOutlinedInput: {
  //       defaultProps: {
  //         margin: "dense",
  //       },
  //     },
  //     MuiTable: {
  //       defaultProps: {
  //         size: "small",
  //       },
  //     },
  //     MuiTextField: {
  //       defaultProps: {
  //         margin: "dense",
  //       },
  //     },
  //     MuiToolbar: {
  //       defaultProps: {
  //         variant: "dense",
  //       },
  //     },
  //   },
  // });
  useEffect(() => {
    props.setContactsDataMap(selectedAccountData)
    console.log("length objectsDataMAP - Contact",[...props.objectsDataMAP].length,)
  })
  return (
    <ThemeProvider theme={UItheme}>
    <React.Fragment>
      <div className="">
        <div className="">
          {/*<div className="button-section">
            <div className="source-provider">
              <div className="autocompelete-section">
                 <Typography variant="body1"> Data Service Provider</Typography>
              &emsp; 
                <FormControl variant="outlined" margin="dense">
                  <InputLabel id="demo-simple-select-label">
                    Data Service Pr...
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Data Service Pr..."
                    value={dataServiceProvider}
                    onChange={handleProviderChange}
                    size="medium"
                    style={{ width: "180px" }}
                  >
                    <MenuItem value={"None"}>Not Available</MenuItem>
                    {/* <MenuItem value={"AdaptIO"}>Adapt IO</MenuItem>
                    <MenuItem value={"Mock"}>MOCK API</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>            
          </div>*/}
        <Paper style={{ borderRadius: '18px', padding: `28px 28px 0px`, boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`, background: `#FFFFFF`, }}>
          <GridTable
            columns={props.columns}
            onColumnsChange={onColumnChangeSave(props.setColumns)}
            rows={rowsData}
            isLoading={isLoading}
            editRowId={editRowId}
            onEditRowIdChange={setEditRowId}
            selectedRowsIds={selectedRowsIds}
            objectsDataMAP={selectedAccountData}
            //onSelectedRowsChange={setSelectedRowsIds}

            onSelectedRowsChange={(arr) => {
              console.log("haha1", arr);
              const added = arr.filter(
                (number) => !selectedRowsIds.includes(number)
              );
              console.log("added", added);
              const removed = selectedRowsIds.filter(
                (number) => !arr.includes(number)
              );
              console.log("removed", removed);
              const accountMap = new Map(selectedAccountData);
              console.log("accountMap", accountMap);

              for (const addedElement of added) {
                for (const rowElement of rowsData) {
                  if (
                    rowElement != undefined &&
                    rowElement.row_id === addedElement
                  ) {
                    accountMap.set(addedElement, rowElement);
                    break;
                  }
                }
              }
              for (const removedElement of removed) {
                accountMap.delete(removedElement);
              }
              console.log("accountMap", accountMap);
              setSelectedAccountData(new Map(accountMap));
              props.setContactsDataMap(selectedAccountData)
              setSelectedRowsIds(arr);
            }}
            onRowClick={(
              { rowIndex, data, column, isEdit, event },
              tableManager
            ) =>
              !isEdit &&
              tableManager.rowSelectionApi.getIsRowSelectable(data.id) &&
              tableManager.rowSelectionApi.toggleRowSelection(data.id)
            }
            // style={{
            //   boxShadow:
            //     "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
            //   borderRadius: "4px",
            //   border: "1px solid #d3d3d3",
            // }}
            onLoad={setTableManager}
            searchText={searchText}
            onSearchTextChange={setSearchText}
            sort={sort}
            onSortChange={setSort}
            page={page}
            onPageChange={setPage}
            showActions={true}
            object={"contact"}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            pageSizes={pageSizes}
            enableColumnsReorder={enableColumnsReorder}
            highlightSearch={highlightSearch}
            showSearch={showSearch}
            showRowsInformation={showRowsInformation}
            showColumnVisibilityManager={showColumnVisibilityManager}
            isHeaderSticky={isHeaderSticky}
            isVirtualScroll={isVirtualScroll}
            isPaginated={isPaginated}
            minSearchChars={minSearchChars}
            minColumnResizeWidth={minColumnResizeWidth}
            selectAllMode={selectAllMode}
            icons={{ columnVisibility: <MoreHorizIcon color="action" /> }}
          />
          </Paper>
          <div className="button-section">
            
            <div className="button-array"></div>
          <div>
              <Button
                // title="Remove All"
                style={{
                  color:selectedRowsIds.length === 0?"rgba(0, 0, 0, 0.26)":"#A0A0A0",
                  borderColor: "#A0A0A0",
                  boxShadow: "0px 4px 5px -2px rgba(200, 200, 200, 0.7)",
                  backgroundColor:"#FFFFFF", 
                  borderRadius:"18px",
                  padding:"2px",
                  width:"104px",
                  fontWeight:700,
                  fontSize:"12px"
                }}
                variant="outlined"
                onClick={(e) => removeRowsFromApi()}
                disabled={selectedRowsIds.length === 0}
              >
                <div style={{paddingRight:"8px"}}>
                  <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.66896 6.92443L6.15443 4.40952L8.4878 2.07615C8.92925 1.63527 8.92925 0.920166 8.4878 0.478909C8.04673 0.0378426 7.33162 0.0380338 6.89094 0.478909L4.55719 2.81228L2.10935 0.364247C1.66809 -0.0768192 0.952983 -0.0768192 0.512109 0.364247C0.0712337 0.805504 0.0712337 1.52023 0.512109 1.96149L2.96014 4.40952L0.330943 7.03852C-0.110314 7.47997 -0.110314 8.1947 0.330943 8.63595C0.772009 9.07683 1.48693 9.07683 1.9278 8.63595L4.55719 6.00676L7.07211 8.52167C7.51317 8.96274 8.22809 8.96274 8.66896 8.52167C9.11041 8.08022 9.11022 7.36512 8.66896 6.92443Z" fill={selectedRowsIds.length === 0?"rgba(0, 0, 0, 0.26)":"#A0A0A0"}/>
                  </svg>
                </div>
                CANCEL
              </Button>
              <Button
                // title="Enrich"
                variant="contained"
                style={{ marginLeft: "10px",                 
                  boxShadow: "0px 4px 5px -2px rgba(200, 200, 200, 0.7)",
                  backgroundColor:selectedRowsIds.length === 0?"rgba(0, 0, 0, 0.12)":"#21DA8C", 
                  borderRadius:"18px",
                  color:selectedRowsIds.length === 0?"rgba(0, 0, 0, 0.12)":"#FFFFFF",
                  padding:"2px",
                  width:"91px",
                  fontWeight:700,
                  fontSize:"12px"
                }}
                disabled={selectedRowsIds.length == 0}
                onClick={(e) => enrichContacts(e)}
                // startIcon={<EnrichIcon color="defult" />}
              >
                <div style={{paddingRight:"8px"}}>
                  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2955 2.82895L5.36063 8.76339C4.92115 9.20296 4.20824 9.20296 3.76834 8.76339L0.704737 5.69955C0.265088 5.25999 0.265088 4.54699 0.704737 4.10734C1.14447 3.66761 1.85732 3.66761 2.29686 4.10718L4.56472 6.37507L9.7031 1.23666C10.1428 0.796924 10.8557 0.797257 11.2953 1.23666C11.7349 1.67631 11.7349 2.38905 11.2955 2.82895Z" fill={selectedRowsIds.length === 0?"rgba(0, 0, 0, 0.12)":"white"}/>
                  </svg>
                </div>
                ENRICH
              </Button>
              <Button
                variant="contained"
                // title="Submit"
                disabled={selectedRowsIds.length === 0}
                onClick={(e) => saveEnrichData(e)}
                style={{ marginLeft: "10px",                 
                  boxShadow: "0px 4px 5px -2px rgba(200, 200, 200, 0.7)",
                  backgroundColor:selectedRowsIds.length === 0?"rgba(0, 0, 0, 0.12)":"#21DA8C", 
                  borderRadius:"18px",
                  color:selectedRowsIds.length === 0?"rgba(0, 0, 0, 0.12)":"#FFFFFF",
                  padding:"2px",
                  width:"91px",
                  fontWeight:700,
                  fontSize:"12px"
                }}
              >
                <div style={{paddingRight:"8px"}}>
                  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2955 2.82895L5.36063 8.76339C4.92115 9.20296 4.20824 9.20296 3.76834 8.76339L0.704737 5.69955C0.265088 5.25999 0.265088 4.54699 0.704737 4.10734C1.14447 3.66761 1.85732 3.66761 2.29686 4.10718L4.56472 6.37507L9.7031 1.23666C10.1428 0.796924 10.8557 0.797257 11.2953 1.23666C11.7349 1.67631 11.7349 2.38905 11.2955 2.82895Z" fill={selectedRowsIds.length === 0?"rgba(0, 0, 0, 0.12)":"white"}/>
                  </svg>
                </div>
                SUBMIT
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
    </ThemeProvider>
  );
};

export default MyAwesomeTable;

//ReactDOM.render(<MyAwesomeTable />, document.getElementById('root'));
