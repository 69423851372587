import React from "react";
import axios from 'axios';
import {useAuthState, AuthProvider} from "@bamboobox/b2logincheck";
 import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
import ValidationUIComponent from './ValidationUIComponent';
 import {saveContactfromRow,check_mandatory_validate_contactFields, getAllRowsData, getAllRowsDatawithId, saveAccountfromRow, saveFundingfromRow, saveCRMfromRow} from './functionRepositoryDataSaveGet';
 import DropdownWithMeta from "./DropdownWithMeta"
 import {API_BASE_URL} from './constants';
 import {validateUrl, validateEmail, validateFloat, validateStringRegex, validateInteger} from './validateInput';
 import Tooltip from '@mui/material/Tooltip';
 import {EDIT_SVG, CANCEL_SVG, SAVE_SVG, REMOVE_SVG, styles} from './constants';
 import Alert from '@mui/material/Alert';
 import "./toast.css"
 
 var personaLevels = [];
 var departments = [];
 // var subdepartments = [];
 var contactRoles = [];
 var countriesList = [];
 var personaCodes = [];
 var capillaryGeography = [];
var crmContactRegionsList = [];
let allMetaDataList = [];


 const localhost = API_BASE_URL;
const GetColumns = ({ setRowsData, metadata, stateMetaData, setSelectedRowsIds, allMetadata }) => {
 
  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: false,
  });
    const userData = useAuthState();
    let tenantId = userData.user.tenantId;
    let userId = userData.user.userUuid;
    allMetaDataList = [...allMetadata];
    if (metadata != undefined && metadata.length > 0 )
    {
        departments = metadata.find(obj => obj.name == "department");
        if (departments){
          departments=departments.values;
        }
        personaLevels =  metadata.find(obj => obj.name == "personaLevel");
        if (personaLevels){
          personaLevels=personaLevels.values;
        }
        countriesList = metadata.find(obj => obj.name == "country");
        if (countriesList){
          countriesList=countriesList.values;
        }
        personaCodes = metadata.find(obj => obj.name == "personaCode")   
        if (personaCodes){
          personaCodes=personaCodes.values;
        }
        contactRoles =  metadata.find(obj => obj.name == "contactRole");
        if (contactRoles){
          contactRoles=contactRoles.values;
        }
        capillaryGeography = metadata.find(obj => obj.name == "geography")
        if (capillaryGeography){
          capillaryGeography = capillaryGeography.values;
        }
        else {
          capillaryGeography = []
        }
        crmContactRegionsList = metadata.filter(obj => obj.name == "accountRegion");
        if (crmContactRegionsList){
          if (crmContactRegionsList.length === 1){
            crmContactRegionsList=crmContactRegionsList[0].values;
          } else if (crmContactRegionsList.length === 2){
            crmContactRegionsList=crmContactRegionsList[1].values;
          } else {
            crmContactRegionsList = [];
          }
        }
        // statesListByCountry = stateMetaData;
    }




    var saveadapt = {
       
        "tenantId": tenantId,//"TTT-ID-00000",
        "userID": userId,//"USR-ID-0000",
        
        "rowSelected": []
    }
    
    
      
    
        const deleteSingleRow = (e,rowIndex,rowsClone)=>{
          var removeRows = {
       
            "tenantId": tenantId,//"TTT-ID-00000",
            "userId": userId,//"USR-ID-0000",
            "rows": []
        }
      console.log("on delete",rowsClone);
      console.log("on delete",rowIndex);
      var cId = {id: rowsClone[rowIndex].contactId};
      console.log("on delete",cId);
      removeRows.rows.push(cId);
      console.log("on delete",removeRows);
      axios.post(localhost+"/b2pm/contact/remove",removeRows,{withCredentials : true})
      .then((response) => {
        if(response.status === 200 && response.data.rows[0].msg==="SUCCESS")
        {
               rowsClone.splice(rowIndex,1);  
          
          setRowsData([...rowsClone]);
            toast.error(<Alert severity="error">Record has been successfully removed from Enrichment Queue</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
          setSelectedRowsIds([]);
        } else {
            toast.error(<Alert severity="error">Failed due to Server error</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
        }
    });
     
      
  }
    //npm.pkg.github.com/:_authToken=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
// email=ashwin@bamboobox.ai
// always-auth=true





    function save_data(rowsClone, updatedRowIndex) {
        // gridData = rowsClone;
          //console.log(gridData);
     
         console.log("SAVEDATA");
         
        
         console.log(rowsClone);
         console.log(updatedRowIndex);
         console.log("-------------")
         
      if(updatedRowIndex+1)  {
         var json = {"row_id" : updatedRowIndex+1 , "contactSource" : rowsClone[updatedRowIndex].contactSource,"msg" : rowsClone[updatedRowIndex].msg };
         
         var contact = saveContactfromRow(rowsClone[updatedRowIndex])
         json["contact"] = contact;
         var account = saveAccountfromRow(rowsClone[updatedRowIndex])
         json["account"] = account;
        
         var funding = saveFundingfromRow(rowsClone[updatedRowIndex])
         json["funding"] = funding;
         var crm= saveCRMfromRow(rowsClone[updatedRowIndex])
         json["crm"] = crm;
        
        saveadapt.rowSelected.push(json);
        console.log("Push SAVEDATA");
       console.log(saveadapt)
     
     
     
      let rowsalldata = [];
              
            axios.post(localhost+"/b2pm/savedata/contact", saveadapt,{withCredentials : true})
            .then((tabledata) => {
             for(let i=0; i<tabledata.data.rowSelected.length ; i++)
             { 
            //   console.log(gridData.rowSelected)
              console.log(tabledata.data.rowSelected[i])
             //gridData.rowSelected[tabledata.data.rowSelected]=tabledata.data.rowSelected[i];
             }
         });
             //setRowsData(gridData);
          
       }
        
       }
    return [
      {
        id: 'checkbox',
        visible: true,
        pinned: true,
        width: '54px',
        },
       //  {
       //  id: 'contactId',
       //  field: 'contactId',
       //  label: 'ContactID',
       //  visible: false,
       //  },
       //  {
       //  id: 'accountId',
       //  field: 'accountId',
       //  label: 'AccountID',
       //  visible: false,
       //  },
        
       {
        id: "companyName",
        field: "companyName",
        label: "Company Name" ,
        visible: true,
        searchable: true,
        editable: false,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
                tableManager,
                value,
                data,
                column,
                colIndex,
                rowIndex,
                onChange
              }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not contain special character"/>
    
        },
        {
          id: "companyWebsite",
          field: "companyWebsite",
          label: "Company Website",
          visible: true,
          searchable: true,
          editable: false,
          sortable: true,
          resizable: true,
          editorCellRenderer: ({
            tableManager,
            value,
            data,
            column,
            colIndex,
            rowIndex,
            onChange
            }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateUrl} message=" is not valid Website"/>
            
        
        
        },
        {
        id: 'contactFirstName',
        field: 'contactFirstName',
        label: 'First Name',
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        headerCellRenderer:({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => ( <span onChange={ onChange } required>First Name <span id="red_color">&#42;</span></span> ),
        editorCellRenderer: ({
         tableManager,
         value,
         data,
         column,
         colIndex,
         rowIndex,
         onChange
       }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />
       
        },
        {
        id: 'contactLastName',
        field: 'contactLastName',
        label: 'Last Name',
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        headerCellRenderer:({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => ( <span onChange={ onChange } required>Last Name <span id="red_color">&#42;</span></span> ),
        editorCellRenderer: ({
         tableManager,
         value,
         data,
         column,
         colIndex,
         rowIndex,
         onChange
       }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />
       
        },
        {
        id: 'contactEmail',
        field: 'contactEmail',
        label: 'Official Email ID',
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        headerCellRenderer:({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => ( <span onChange={ onChange } required>Official Email ID<span id="red_color">&#42;</span></span> ),
        editorCellRenderer: ({
          tableManager,
          value,
          data,
          column,
          colIndex,
          rowIndex,
          onChange
        }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateEmail} message=" is not valid email" placeholder="abc@example.com"/>
        
        
        }, 
		
		 {
        id: 'contactPositionLevel',
        field: 'contactPositionLevel',
        label: 'Persona Level',
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
           tableManager,
           value,
           data,
           column,
           colIndex,
           rowIndex,
           onChange
         }) => <DropdownWithMeta allMetaData={allMetaDataList} source="CONTACT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={personaLevels} />
         
        },
        
        
        {
        id: 'contactDepartment',
        field: 'contactDepartment',
        label: 'Department',
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
           tableManager,
           value,
           data,
           column,
           colIndex,
           rowIndex,
           onChange
         }) => <DropdownWithMeta allMetaData={allMetaDataList} source="CONTACT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={departments} />
         
        },
        
		 {
        id: 'contactTitle',
        field: 'contactTitle',
        label: 'Title',
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
         tableManager,
         value,
         data,
         column,
         colIndex,
         rowIndex,
         onChange
       }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />
       
        
        },
        {
        id: 'contactPhone',
        field: 'contactPhone',
        label: 'Phone',
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
          tableManager,
          value,
          data,
          column,
          colIndex,
          rowIndex,
          onChange
        }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />
        
        },
        {
        id: 'contactMobile',
        field: 'contactMobile',
        label: 'Mobile',
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
         tableManager,
         value,
         data,
         column,
         colIndex,
         rowIndex,
         onChange
       }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />
       
        
        },
		
		
     
          
        {
        id: 'contactCountry',
        field: 'contactCountry',
        label: 'Mailing Country',
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
           tableManager,
           value,
           data,
           column,
           colIndex,
           rowIndex,
           onChange
         }) => <DropdownWithMeta allMetaData={allMetaDataList} source="CONTACT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={countriesList} />
         
        },
        {
           id: 'contactState',
           field: 'contactState',
           label: 'Mailing State',
           visible: true,
           searchable: true,
           editable: true,
           sortable: true,
           resizable: true,
           editorCellRenderer: ({
             tableManager,
             value,
             data,
             column,
             colIndex,
             rowIndex,
             onChange
           }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />
           
           // editorCellRenderer: ({
           //     tableManager,
           //     value,
           //     data,
           //     column,
           //     colIndex,
           //     rowIndex,
           //     onChange
           //   }) => <DropdownWithMeta propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={statesListByCountry} parent={"contactCountry"} state={true} />
           
           },
        {
           id: 'contactCity',
           field: 'contactCity',
           label: 'Mailing City',
           visible: true,
           searchable: true,
           editable: true,
           sortable: true,
           resizable: true,
           editorCellRenderer: ({
             tableManager,
             value,
             data,
             column,
             colIndex,
             rowIndex,
             onChange
           }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />
           
           // editorCellRenderer: ({
           //     tableManager,
           //     value,
           //     data,
           //     column,
           //     colIndex,
           //     rowIndex,
           //     onChange
           //   }) => <DropdownWithMeta propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={[]} parent={"contactState"} city={true} />
           },
		     
        {
         id: 'crmPersonaCode',
         field: 'crmPersonaCode',
         label: 'Persona code',
         visible: true,
         searchable: true,
         editable: true,
         sortable: true,
         resizable: true,
         editorCellRenderer: ({
           tableManager,
           value,
           data,
           column,
           colIndex,
           rowIndex,
           onChange
         }) => <DropdownWithMeta allMetaData={allMetaDataList} source="CONTACT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={personaCodes} />
         
         },
       
		        {
        id: 'contactRole',
        field: 'contactRole',
        label: 'Contact Role',
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
         tableManager,
         value,
         data,
         column,
         colIndex,
         rowIndex,
         onChange
       }) => <DropdownWithMeta allMetaData={allMetaDataList} source="CONTACT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={contactRoles} />
       
        
        },
        {
        id: 'contactLinkedin',
        field: 'contactLinkedin',
        label: 'Contact Linkedin URL',
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
         tableManager,
         value,
         data,
         column,
         colIndex,
         rowIndex,
         onChange
       }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateUrl} message=" is not valid URL" placeholder="example.com"/>
        },

		   {
        id: 'contactSecondaryEmail',
        field: 'contactSecondaryEmail',
        label: 'Other Email ID',
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
          tableManager,
          value,
          data,
          column,
          colIndex,
          rowIndex,
          onChange
        }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateEmail} message=" is not valid email" placeholder="abc@example.com"/>
        
        },
        {
        id: 'contactSecondaryNumber',
        field: 'contactSecondaryNumber',
        label: 'Other Number',
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
         tableManager,
         value,
         data,
         column,
         colIndex,
         rowIndex,
         onChange
       }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />
       
        
        },
       
       
            {
              id: 'crmContactGeography',
              field: 'crmContactGeography',
              label: 'Geography',
              visible: true,
              searchable: true,
              editable: true,
              sortable: true,
              resizable: true,
              editorCellRenderer: ({
                tableManager,
                value,
                data,
                column,
                colIndex,
                rowIndex,
                onChange
              }) => <DropdownWithMeta allMetaData={allMetaDataList} source="CONTACT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={capillaryGeography} />
              
              
              },
           {
            id: 'contactRegion',
            field: 'contactRegion',
            label: 'Region',
            visible: true,
            searchable: true,
            editable: true,
            sortable: true,
            resizable: true,
            editorCellRenderer: ({
              tableManager,
              value,
              data,
              column,
              colIndex,
              rowIndex,
              onChange
            }) => <DropdownWithMeta allMetaData={allMetaDataList} source="CONTACT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={crmContactRegionsList} level="1" parent="crmContactGeography"/>
            
            
            },
       
       
       
        {
        id: 'contactSource',
        field: 'contactSource',
        label: 'Contact Source',
        visible: true,
        searchable: true,
        editable: false,
        sortable: true,
        resizable: true,
        
        },
        {
          id: 'enriched',
          field: 'enriched',
          label: 'enriched',
          visible: false,
          searchable: false,
          editable: false,
          sortable: true,
          resizable: false,
          
          },
        {
            id: 'buttons',
            visible: true,
            pinned: true,
            sortable: false,
            resizable: false,
            width: "65px",
            cellRenderer: ({ tableManager, value, data, column, colIndex, rowIndex }) => (
                <div style={styles.buttonsCellContainer}>
                  <Tooltip  title="Edit">
                    <button
                      style={styles.editButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        tableManager.rowEditApi.setEditRowId(data.id);
                      }}
                    >
                      {EDIT_SVG}
                    </button>
                  </Tooltip>
           <button
                        id = {rowIndex}
                       title="Remove"
                        style={styles.removeButton}
                        onClick={e=> {
                          let rowsClone = [...tableManager.rowsApi.rows];
                            let updatedRowIndex = rowsClone.findIndex(r => r.id === data.id);
                          deleteSingleRow(e,updatedRowIndex,rowsClone)
                          
                        }
                      
                      }
                    >
                  
                    {REMOVE_SVG}
                    </button>
        </div>
      ),
            editorCellRenderer: ({ tableManager, value, data, column, colIndex, rowIndex, onChange }) => (
                <div style={styles.buttonsCellEditorContainer}>
                  <Tooltip title="Cancel">
                    <button
                        title="Cancel"
                        style={styles.cancelButton}
                        onClick={e => {e.stopPropagation(); tableManager.rowEditApi.setEditRowId(null)}}
                    >
                        {CANCEL_SVG}
                    </button>
                    </Tooltip>
                    <Tooltip title="Save">
                    <button
                        style={styles.saveButton}
                        onClick={e => {
                            e.stopPropagation();
                            let rowsClone = [...tableManager.rowsApi.rows];
                            let updatedRowIndex = rowsClone.findIndex(r => r.id === data.id);
                            rowsClone[updatedRowIndex] = data;
                            let [mandatoryFieldsError, validationFieldErrorList] = check_mandatory_validate_contactFields(rowsClone[updatedRowIndex])
    
                        if (mandatoryFieldsError) {
                          
                          console.log("mandatory fields error", mandatoryFieldsError)
                            toast.error(<Alert severity="error">{mandatoryFieldsError}</Alert>,{
                              closeOnClick: true,
                              autoClose: true,
                              closeButton: true,
                              newestOnTop: true
                            });
                          return;
                        }
                        
                        if (validationFieldErrorList.length > 0){
                          console.log("validationFieldErrorList size", validationFieldErrorList.length)
                          for (let i=0; i < validationFieldErrorList.length; i++) {
                            
                            console.log("validationFieldErrorList",validationFieldErrorList[i])
                              toast.error(<Alert severity="error">{validationFieldErrorList[i]}</Alert>,{
                                closeOnClick: true,
                                autoClose: true,
                                closeButton: true,
                                newestOnTop: true
                              });
                          }
                          return;
                        }

                            
                            setRowsData(rowsClone);
                            tableManager.rowEditApi.setEditRowId(null);
                            save_data(rowsClone, updatedRowIndex);
                        }}
                    >
                        {SAVE_SVG}
                    </button>
                    </Tooltip>
                </div>
            )
        }
    ]
}

export default GetColumns;
