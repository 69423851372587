import React, { useEffect, useState } from "react";
import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {myConstClass} from "./constants.js";
import {useAuthState} from "@bamboobox/b2logincheck";

const localhost = myConstClass.localhost; 

function DropdownWithMeta(props)
{
  const [cstateValue, setCStateValue] = useState("");
  const [cityValues, setCityValues] = useState([]);
  const userData = useAuthState();
  let tenantId = userData.user.tenantId;
  let userId = userData.user.userUuid;

  var values = [];
  let parentFieldName='';

  const getParentObj = (hierarchy, position, allMetaData) => {
    return allMetaData.filter((obj) => obj.hierarchy === hierarchy && obj.position === position && obj.source === props.source)
  }

  const findDropdownObj = (fieldName, allMetaData) => {
    return allMetaData.filter((obj) => obj.name === fieldName)
  }
  console.log("DropdownWithMeta allmetadata", props.allMetaData)
  let dropDownObj = findDropdownObj(props.column.field, props.allMetaData || [])[0];
  console.log("DropdownWithMeta dropdownObj", dropDownObj)
  values = dropDownObj?.values;
  if (dropDownObj?.position > 1){
    let parentObj = getParentObj(dropDownObj?.hierarchy, dropDownObj?.position - 1, props.allMetaData || [])[0]
    console.log("DropdownWithMeta parentObj", parentObj);
    parentFieldName = parentObj?.name
    console.log("DropdownWithMeta parentFieldName", parentFieldName)
  } 
  
 
  var getCities = async (state) => {
    let response = await fetch(localhost+"/b2pm/metadata/statesToCity?State="+state,{credentials:'include'});
    let data = await response.json()
    var dropdowndata = [];
    data.forEach(element => {
      dropdowndata.push({value:element})
    });
    
    return dropdowndata;
  };
  
  
  
  if (!(Array.isArray(values) && values?.length > 0)){
    values = [];
  }

  console.log("dropdown values", values)
  if (parentFieldName) { 
    var parentSelectedvalue = props.data[parentFieldName]; 
    console.log("DropdownWithMeta parentSelectedvalue", parentSelectedvalue)
    if (props.city) 
    {
      if (cstateValue === parentSelectedvalue) {
        values = cityValues;
      }
      else if (parentSelectedvalue)
      {
       getCities(parentSelectedvalue).then(
          (cities) => {setCityValues(cities);setCStateValue(parentSelectedvalue)}  )
      }
    }
    // else if (props.state) {
    //   values = values.filter(item => item.country === parentSelectedvalue);
    //   values = (values[0]?values[0].states:[]); 
    //   values.map(item => item.value = item.state); 
    // }
    else {
      values = values.filter(item => item.parentvalue === parentSelectedvalue ); 
      values = (values[0]?values[0].childvalues:[]); 
      values.map(item => item.value = item.childvalue); 
    } 
  }
  console.log("dropdown values final", values)
  
return <Autocomplete style={{ width: "98%", margin: "2", marginBottom:"1%" }} 
onChange={(e, option) => {
  props.onChange({ ...props.data, [props.column.field]: option? option.value: ""});    
}} 
options={values}
value={values.find(option => option.value === props.value) || {}}
renderOption={(option) => (
  <React.Fragment>
    {option.value}
  </React.Fragment>
)}
getOptionLabel = {(option) =>option.value}
renderInput={(params) => (
  <TextField
    {...params}
    label=""
       inputProps={{
      ...params.inputProps,
      disableUnderline: true}}
  />
)} />
}

export default DropdownWithMeta;