import validator from 'validator';



export function validateUrl(input) {


    if (!input || validator.isEmpty(input)) {
        
        return true;
    }
    console.log("url valid", true)
    return validator.isURL(input);
}


export function validateEmail(input) {


    if (!input ||  validator.isEmpty(input)) {
        return true;
    }

    return validator.isEmail(input);
}

export function validateStringRegex(input, regex = "^([A-Za-z0-9- –_#,+<>=./?&()]+)$") {


    if (!input ||  validator.isEmpty(input)) {
        return true;
    }

    let regexp = new RegExp(regex)
    return regexp.test(input.trim());
}

export function validateFloat(input) {
    if (!input) {
        return true;
    }
    input = input.toString();

    if (validator.isEmpty(input)) {
        return true;
    }

    return validator.isFloat(input);
}

export function validateInteger(input) {
    if (!input) {
        return true;
    }
    input = input.toString();

    if (validator.isEmpty(input)) {
        return true;
    }

    return validator.isNumeric(input);
}