import { getThemeProps } from "@material-ui/styles";

export default {
  fontFamily: [
    '"Montserrat"',
    '"Poppins"',
    '"Roboto"',
    '"Segoe UI"',
    '"Helvetica Neue"',
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  h1: {
    fontSize: "96px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "112px",
    letterSpacing: "-1.5px",
    textAlign: "left",
  },
  h2: {
    fontWeight: 500,
    fontSize: "96px",
    letterSpacing: "-0.24px",
  },
  h3: {
    fontWeight: 500,
    fontSize: 48,
    letterSpacing: "-0.06px",
  },
  h4: {
    fontWeight: 500,
    fontSize: 34,
    letterSpacing: "-0.06px",
  },
  h5: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: "-0.05px",
  },
  h6: {
    fontFamily: "'Poppins'",
    fontWeight: "normal",
    fontSize: 20,
    letterSpacing: "-0.05px",
  },
  button: {
    fontFamily: "'Poppins'",
    fontStyle: "none",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "26px",
  },
  overline: {
    fontWeight: 500,
  },
};
