import React, { useState ,useRef} from "react";
import axios from 'axios';
import GridTable from "./grid_src";
// import GridTable from "@nadavshaar/react-grid-table";
import {useAuthState, AuthProvider} from "@bamboobox/b2logincheck";
import {getAllRowsData, getAllRowsDatawithId, check_mandatory_validate_accountFields, saveAccountfromRow, saveFundingfromRow, saveCRMfromRow} from './functionRepositoryDataSaveGet';
import DropdownWithMeta from "./DropdownWithMeta"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
import ValidationUIComponent from './ValidationUIComponent';
import {API_BASE_URL,AUTH_URL,LOGIN_URL, EDIT_SVG, CANCEL_SVG, SAVE_SVG, REMOVE_SVG, styles} from './constants';
import Tooltip from '@mui/material/Tooltip';
import {validateUrl, validateEmail, validateStringRegex, validateFloat, validateInteger} from './validateInput';
//const localhost = window._env_.API_DOMAIN;
 //const localhost = "http://localhost:8080";
 import Alert from '@mui/material/Alert';
 import "./toast.css"
 
 var accountClassification = [];
 var industriesList = [];
 var subindustriesList = [];
 var countriesList = [];
 var crmAccountRegionsList = [];
 var statesListByCountry = [];
 var companyType = [];
 var companyHierarchy = [];
 var capillaryGeography = [];
 var crmBusinessFormatList = [];
 var isExecList = [];
 var fsExecList = [];
 let allMetaDataList = [];
const localhost =API_BASE_URL;


const GetColumns = ({ setRowsData, metadata, stateMetaData, setSelectedRowsIds, fsExecdata, isExecMetaData, allMetadata }) => {
  const userData = useAuthState();

  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: false,
  });
  

  let tenantId = userData.user.tenantId;
  let userId = userData.user.userUuid;

  allMetaDataList = [...allMetadata];
  if (metadata != undefined && metadata.length > 0 )
 {
   console.log("within metadata");
   accountClassification = metadata.find(obj => obj.name == "accountClassification");
   if (accountClassification){
    accountClassification=accountClassification.values;
   }else {
    accountClassification = []
   }
   industriesList = metadata.find(obj => obj.name == "industry");
   if(industriesList){
     industriesList=industriesList.values;
   }else {
    industriesList = []
   }

   crmBusinessFormatList = metadata.find(obj => obj.name == "businessFormat");
   if(crmBusinessFormatList){
     crmBusinessFormatList=crmBusinessFormatList.values;
   }else {
    crmBusinessFormatList = []
   }

   countriesList = metadata.find(obj => obj.name == "country")
   if(countriesList){
     countriesList=countriesList.values;
   }else {
    countriesList = []
   }
   // statesListByCountry = stateMetaData;
   subindustriesList = metadata.filter(obj => obj.name == "subIndustry")
   if (subindustriesList){
     if (subindustriesList.length === 1){
       subindustriesList=subindustriesList[0].values;
     } else if (subindustriesList.length === 2){
       subindustriesList=subindustriesList[1].values;
     } else {
       subindustriesList = [];
     }
   }
   crmAccountRegionsList = metadata.filter(obj => obj.name == "accountRegion");
   if (crmAccountRegionsList){
     if (crmAccountRegionsList.length === 1){
       crmAccountRegionsList=crmAccountRegionsList[0].values;
     } else if (crmAccountRegionsList.length === 2){
       crmAccountRegionsList=crmAccountRegionsList[1].values;
     } else {
       crmAccountRegionsList = [];
     }
   }
   companyType = metadata.find(obj => obj.name == "companyType")
   if (companyType){
     companyType = companyType.values;
   } else {
    companyType = []
   }
   companyHierarchy = metadata.find(obj => obj.name == "companyHierarchy")
   if (companyHierarchy){
     companyHierarchy = companyHierarchy.values;
   }else {
    companyHierarchy = []
   }
   capillaryGeography = metadata.find(obj => obj.name == "geography")
   if (capillaryGeography){
     capillaryGeography = capillaryGeography.values;
   }
   else {
    capillaryGeography = []
   }
   if (fsExecdata){
     fsExecList=[]
     console.log("fsExecdata",fsExecdata);
     for (let i=0; i < fsExecdata.length; i++){
       fsExecList.push(fsExecdata[i])
       fsExecList[i].value = fsExecdata[i].name;
     }
   } else {
     fsExecList = [];
   }

   if (isExecMetaData){
     isExecList=[]
     console.log("isExecMetaData",isExecMetaData);
     for (let i=0; i < isExecMetaData.length; i++){
       isExecList.push(isExecMetaData[i])
       isExecList[i].value = isExecMetaData[i].name;
     }
   } else {
     isExecList = [];
   }
 }



    var saveadapt = {
       
      "tenantId": tenantId,//"TTT-ID-00000",
      "userId": userId,//"USR-ID-0000",
        "msg" :"",
        "rowSelected": []
    }

     
    
  const deleteSingleRow = (e,rowIndex,rowsClone)=>{
    var removeRows = {
       
      "tenantId": tenantId,//"TTT-ID-00000",
      "userId": userId,//"USR-ID-0000",
        "rows": []
    }
      console.log("on delete",rowsClone)
      console.log("on delete",rowIndex);
      var cId = {id: rowsClone[rowIndex].accountId};
      console.log("on delete",cId);
      removeRows.rows.push(cId);
      console.log("on delete");
      axios.post(localhost+"/b2pm/account/remove",removeRows,{withCredentials : true})
      .then((response) => {

        if(response.status === 200 && response.data.rows[0].msg==="SUCCESS")
        {
          console.log("rowIndex -> ", rowIndex)
          rowsClone.splice(rowIndex,1);  
          
          setRowsData([...rowsClone]);
          toast.error(<Alert severity="error">Record has been successfully removed from Enrichment Queue</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
          setSelectedRowsIds([])
        } else {
          toast.error(<Alert severity="error">Failed due to Server error</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
        }
    }).catch(e=>{
      console.log(e)
        toast.error(<Alert severity="error">Error Occured while deleting</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
    });
     
      
  }

  function save_data(rowsClone, updatedRowIndex) {
   // gridData = rowsClone;
     //console.log(gridData);
    
    console.log("SAVEDATA");
    
   
    console.log(rowsClone);
    console.log(updatedRowIndex);
    console.log("-------------")
    
 if(updatedRowIndex+1)  {
    var json = {"row_id" : updatedRowIndex+1 , "accountSource" : rowsClone[updatedRowIndex].accountSource,"msg" : rowsClone[updatedRowIndex].msg };
    var account = saveAccountfromRow(rowsClone[updatedRowIndex])
    json["account"] = account;
   // var contact = saveContactfromRow(rowsClone[updatedRowIndex])
    //json["contact"] = contact;
    var funding = saveFundingfromRow(rowsClone[updatedRowIndex])
    json["funding"] = funding;
    var crm= saveCRMfromRow(rowsClone[updatedRowIndex])
    json["crm"] = crm;
   
   saveadapt.rowSelected.push(json);
   console.log("Push SAVEDATA");
  console.log(saveadapt)



 let rowsalldata = [];
        axios.post(localhost+"/b2pm/savedata/account", saveadapt,{withCredentials : true})
       .then((tabledata) => {
        for(let i=0; i<tabledata.data.rowSelected.length ; i++)
        { 
       //   console.log(gridData.rowSelected)
         console.log(tabledata.data.rowSelected[i])
        //gridData.rowSelected[tabledata.data.rowSelected]=tabledata.data.rowSelected[i];
        }
         //console.log(gridData)
    });
        //setRowsData(gridData);
     
  }

  }
  return [
    {
      id: "checkbox",
      visible: true,
      pinned: true,
      width: "54px",
      },
      // {
      // id: "accountId",
      // field: "accountId",
      // label: "AccountID",
      // visible:false,
      // searchable: false,
      // editable: false,
      // sortable: false,
      // resizable: false,
      // },
      {
      id: "companyName",
      field: "companyName",
      label: "Company Name" ,
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      headerCellRenderer:({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => ( <span onChange={ onChange } required>Company Name<span id="red_color">&#42;</span></span> ),
      editorCellRenderer: ({
              tableManager,
              value,
              data,
              column,
              colIndex,
              rowIndex,
              onChange
            }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not contain special character"/>
  
      },
  
  
      {
      id: "companyWebsite",
      field: "companyWebsite",
      label: "Company Website",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      headerCellRenderer:({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => ( <span onChange={ onChange } required>Company Website<span id="red_color">&#42;</span></span> ),
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
        }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateUrl} message=" is not valid Website"/>
        
    
    
    },
      {
      id: "companyParent",
      field: "companyParent",
      label: "Parent Company",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) =>  <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character"/>
      
      },
      {
      id: "companyGroupName",
      field: "companyGroupName",
      label: "Group Name",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) =>  <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character"/>
      
      },
     
      
     
      {
      id: "companyParentWebsite",
      field: "companyParentWebsite",
      label: "Parent Website",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateUrl} message=" is not a valid website" placeholder="example.com"/>
      },
      {
      id: "companyGroupWebsite",
      field: "companyGroupWebsite",
      label: "Group Website",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateUrl} message=" is not valid website" placeholder="example.com"/>
      },
      {
      id: "companyPrimaryIndustryCategory",
      field: "companyPrimaryIndustryCategory",
      label: "Industry",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={industriesList} />
      },
      {
      id: "companySubIndustryCategory",
      field: "companySubIndustryCategory",
      label: "Sub-Industry",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={subindustriesList} level="1" parent="companyPrimaryIndustryCategory"/>
      },
     
      {
      id: "companyBrandRevenueNumerical",
      field: "companyBrandRevenueNumerical",
      label: "Company Revenue",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateFloat} message=" should be a decimal value" adornment="$" placeholder="1000000.00"/>
      
      },
  
      
      {
      id: "companyPhone",
      field: "companyPhone",
      label: "Phone",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) =>  <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character"/>
      
      },
      {
        id: "companyCountry",
        field: "companyCountry",
        label: "Mailing Country",
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
          tableManager,
          value,
          data,
          column,
          colIndex,
          rowIndex,
          onChange
        }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={countriesList} />
        },
     
      {
      id: "companyState",
      field: "companyState",
      label: "Mailing State",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) =>  <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character"/>
      
      // editorCellRenderer: ({
      //   tableManager,
      //   value,
      //   data,
      //   column,
      //   colIndex,
      //   rowIndex,
      //   onChange
      // }) => <DropdownWithMeta propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={statesListByCountry} parent={"companyCountry"} state={true} />
      
      },
     
      {
        id: "companyCity",
        field: "companyCity",
        label: "Mailing City",
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
          tableManager,
          value,
          data,
          column,
          colIndex,
          rowIndex,
          onChange
        }) =>  <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character"/>
        
        // editorCellRenderer: ({
        //   tableManager,
        //   value,
        //   data,
        //   column,
        //   colIndex,
        //   rowIndex,
        //   onChange
        // }) => <DropdownWithMeta propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={[]} parent={"companyState"} city={true} />
        }, 
     
      {
      id: "19",
      field: "companyDescription",
      label: "Company Description",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) =>  <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character"/>
      
      },
     
      {
      id: "20",
      field: "companyLinkedin",
      label: "Linkedin URL",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateUrl} message=" is not website" placeholder="example.com"/>
      
      },
     
      {
      id: "21",
      field: "companyFacebook",
      label: "FaceBook URL",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateUrl} message=" is not a valid website" placeholder="example.com"/>
      
      },
     
      {
      id: "22",
      field: "companyTwitter",
      label: "Twitter URL",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateUrl} message=" is not a valid website" placeholder="example.com"/>
      
      },
      
      {
      id: "companyType",
      field: "companyType",
      label: "Company Type",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={companyType} />
      
      },
     
      {
      id: "companyHierarchy",
      field: "companyHierarchy",
      label: "Company Hierarchy",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={companyHierarchy} />
      
      },
     
      {
      id: "companyTechAttributes",
      field: "companyTechAttributes",
      label: "Tech Attributes",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character"/>
      
      },
      {
        id: "companyClassification",
        field: "companyClassification",
        label: "Account Classification",
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
          tableManager,
          value,
          data,
          column,
          colIndex,
          rowIndex,
          onChange
        }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={accountClassification} />
        },
      
      {
      id: "crmFSExecutive",
      field: "crmFSExecutive",
      label: "Field Sales",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={fsExecList} />
      
      },
      {
      id: "crmISExecutive",
      field: "crmISExecutive",
      label: "Inside Sales",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={isExecList} />
      
      },
      // {
      // id: "crmFmExecutive",
      // field: "crmFmExecutive",
      // label: "Field Marketing",
      // visible: true,
      // searchable: true,
      // editable: true,
      // sortable: true,
      // resizable: true,
      // },
      {
      id: "crmBusinessFormat",
      field: "crmBusinessFormat",
      label: "Business Format",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={crmBusinessFormatList} />
      
      },
      {
        id: "numberOfContacts",
        field: "numberOfContacts",
        label: "Number of Contacts",
        visible: true,
        searchable: true,
        editable: false,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
          tableManager,
          value,
          data,
          column,
          colIndex,
          rowIndex,
          onChange
        }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateInteger} message="  should be a number" placeholder="100"/>
        
      },
      {
        id: "crmNumberOfStores",
        field: "crmNumberOfStores",
        label: "Number of Stores",
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
          tableManager,
          value,
          data,
          column,
          colIndex,
          rowIndex,
          onChange
        }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateInteger} message="  should be a number" placeholder="100"/>
        
        },
        {
          id: "crmAccountGeography",
          field: "crmAccountGeography",
          label: "Capillary Geography",
          visible: true,
          searchable: true,
          editable: true,
          sortable: true,
          resizable: true,
          editorCellRenderer: ({
            tableManager,
            value,
            data,
            column,
            colIndex,
            rowIndex,
            onChange
          }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={capillaryGeography} />
          
          },
          {
            id: "companyAccountRegion",
            field: "companyAccountRegion",
            label: "Account Region",
            visible: true,
            searchable: true,
            editable: true,
            sortable: true,
            resizable: true,
            editorCellRenderer: ({
              tableManager,
              value,
              data,
              column,
              colIndex,
              rowIndex,
              onChange
            }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={crmAccountRegionsList} level="1" parent="crmAccountGeography"/>
            
            },
      {
      id: "39",
      field: "accountSource",
      label: "Account Source",
      visible: true,
      searchable: true,
      editable: false,
      sortable: true,
      resizable: true,
      },
      {
        id: 'enriched',
        field: 'enriched',
        label: 'enriched',
        visible: false,
        searchable: false,
        editable: false,
        sortable: false,
        resizable: false,
        
        },
      // {
      // id: "msg",
      // field: "msg",
      // label: "msg",
      // visible: false,
      // searchable: false,
      // editable: false,
      // sortable: false,
      // resizable: true,
      // },
    
    {
      id: "buttons",
     
      width: "max-content",
      visible: true,
      pinned: true,
      sortable: false,
      resizable: false,
      cellRenderer: ({ tableManager, value, data, column, colIndex, rowIndex }) => (
        <div style={styles.buttonsCellContainer}>
          <Tooltip  title="Edit">
          <button
            title="Edit"
            style={styles.editButton}
            onClick={(e) => {
              e.stopPropagation();
              tableManager.rowEditApi.setEditRowId(data.id);
            }}
          >
            {EDIT_SVG}
          </button>
          </Tooltip>
          <Tooltip title="Remove">
           <button
                        id = {rowIndex}
                        style={styles.removeButton}
                        onClick={e=> {
                          let rowsClone = [...tableManager.rowsApi.rows];
                          let updatedRowIndex = rowsClone.findIndex((r) => r.id === data.id);
                          deleteSingleRow(e,updatedRowIndex, rowsClone)
                          
                        }}
                       
                    >
                  
                    {REMOVE_SVG}
                    </button>
                    </Tooltip>
        </div>
      ),
      editorCellRenderer: ({ tableManager, value, data, column, colIndex, rowIndex, onChange }) => (
        <div style={styles.buttonsCellEditorContainer}>
          <Tooltip title="Cancel">
          <button
            style={styles.cancelButton}
            onClick={(e) => {
              e.stopPropagation();
              tableManager.rowEditApi.setEditRowId(null);
            }}
          >
            {CANCEL_SVG}
          </button>
          </Tooltip>
          <Tooltip title="Save">
          <button
            style={styles.saveButton}
            onClick={(e) => {
              let error=false;
              e.stopPropagation();
              let rowsClone = [...tableManager.rowsApi.rows];
              let updatedRowIndex = rowsClone.findIndex((r) => r.id === data.id);
              rowsClone[updatedRowIndex] = data;
              let [mandatoryFieldsError, validationFieldErrorList] = check_mandatory_validate_accountFields(rowsClone[updatedRowIndex])
              console.log("mandatoryFieldsError", mandatoryFieldsError)
              console.log("validationFieldErrorList", validationFieldErrorList)
              if (mandatoryFieldsError) {
                error=true;
                console.log("mandatory fields error", mandatoryFieldsError)
                toast.error(<Alert severity="error">{mandatoryFieldsError}</Alert>,{
                  closeOnClick: true,
                  autoClose: true,
                  closeButton: true,
                  newestOnTop: true
                });
                return;
              }
              
              if (validationFieldErrorList.length > 0){
                error=true;
                console.log("validationFieldErrorList size", validationFieldErrorList.length)
                for (let i=0; i < validationFieldErrorList.length; i++) {
                  
                  console.log("validationFieldErrorList",validationFieldErrorList[i])
                  toast.error(<Alert severity="error">{validationFieldErrorList[i]}</Alert>,{
                    closeOnClick: true,
                    autoClose: true,
                    closeButton: true,
                    newestOnTop: true
                  });
                }
                return;
              }


              if (error){
                tableManager.rowEditApi.setEditRowId(data.id);
              } else {
              
              setRowsData(rowsClone);
              tableManager.rowEditApi.setEditRowId(null);
              save_data(rowsClone, updatedRowIndex);
              }
            }}
          >
            {SAVE_SVG}
          </button>
          </Tooltip>
        </div>
      ),
    },
  ];
};


export default GetColumns;

