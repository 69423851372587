import { createMuiTheme, colors } from "@material-ui/core";
import shadows from "./shadows";
import typography from "./typography";
import MuiButton from "./muiButton";
import MuiButtonBase from "./muiButtonBase";
import MuiFormControl from "./muiFormControl";
import MuiFilledInput from "./muiFilledInput";

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#F4F6F8",
      paper: colors.common.white,
    },
    primary: {
      contrastText: "#ffffff",
      main: "#109CF1",
    },
    text: {
      primary: "#172b4d",
      secondary: "#6b778c",
    },
  },
  shadows,
  typography,
  components: {
    MuiButton,
    MuiFilledInput,
    //  muiIconButton,
    MuiButtonBase,
    MuiFormControl,
  },
});

export default theme;
