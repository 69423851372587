export default {
    search: 'Global search:',
    totalRows: 'TOTAL ROWS:',
    rows: 'Rows:',
    selected: 'Selected',
    rowsPerPage: 'ROWS PER PAGE:',
    page: 'Page:',
    of: 'of',
    prev: 'Prev',
    next: 'Next',
    columnVisibility: 'COLUMN VISIBILITY',
    noResults: 'No Results found',
};